let servicesVideos = {
    inited: false,
    children: null,
    parent: null,
    rootFolder: '',
    videosCount: null,
    timerList: new Array(3),
    teamList: [],
    createVideos() {
        let html = this.teamList.reduce((sum, current, teamIndex) => {
            let html = '<div class="servicesVideosItem">';
            current.forEach((element, index) => {
                html += `<video class="${!index ? 'active' : ''}" src="${this.rootFolder + element}" data-team_index="${teamIndex}" data-index="${index}" muted="muted" preload="auto" playsinline ></video>`;
            });
            html += '<div class="bg"></div>'
            return sum + html + '</div>'
        }, '');
        this.parent.html(html);
    },
    init() {
        this.rootFolder = localhost ? "/packages/services/" : "";
        this.teamList = (() => {
            if (localhost) {
                return [
                    [
                        '1.1.mp4',
                        '1.2.mp4',
                        '1.3.mp4',
                        '1.4.mp4',
                        '1.5.mp4',
                    ],
                    [
                        '2.1.mp4',
                        '2.2.mp4',
                        '2.3.mp4',
                        '2.4.mp4',
                        '2.5.mp4',
                    ],
                    [
                        '3.1.mp4',
                        '3.2.mp4',
                        '3.3.mp4',
                        '3.4.mp4',
                        '3.5.mp4',
                    ],
                ]
            } else {
                return packagesData.teamList.map(main => {
                    return main.map(({ video }) => video)
                });
            }
        })();
        this.videosCount = this.teamList[0].length;
        this.parent = $('#servicesVideos');
        this.createVideos();
        this.children = this.parent.find(".servicesVideosItem");
        const thise = this;
        this.children.find("video").each(function () {
            $(this).on('ended', thise.videoEndCallback.bind(thise));
            $(this).on('play', thise.videoPlayCallback.bind(thise));
        });
        this.inited = true;
    },
    stopAllVideos(all = false, index = 0) {
        const team = all ? this.children : this.children.eq(index)
        team.find("video").each(function () {
            $(this)[0].pause();
            $(this)[0].currentTime = 0;
        })
    },
    playAllVideos(all = false, index = 0) {
        const team = all ? this.children : this.children.eq(index)
        team.find("video").each(function () {
            $(this)[0].play();
            $(this)[0].currentTime = 0;
        })
    },
    animations: {
        firstIn() {
            this.stopAllVideos(true);
            this.children.find("video:first-child").each(function () {
                $(this).fadeTo(0, 0).fadeTo(1000, 1)
                $(this)[0].play();
            });
        },
        firstInTeam(index = 0) {
            this.children.eq(index).find('.bg').stop(true).fadeTo(0, 1).fadeTo(500, 0);
            const el = this.children.eq(index).find("video[data-index=0]");
            this.videoEndCallbackFunc(el, 0, index, el)
        },
        teamSelected(index = 0) {
            this.children.eq(index).find('.bg').stop(true).fadeTo(0, 1).fadeTo(500, 0);
            const el = this.children.eq(index).find("video[data-index=2]");
            this.videoEndCallbackFunc(el, 0, index, el)
        },
        teamUnSelected(index = 0) {
            this.children.eq(index).find('.bg').stop(true).fadeTo(0, 1).fadeTo(500, 0);
            const el = this.children.eq(index).find("video[data-index=4]");
            this.videoEndCallbackFunc(el, 0, index, el)
        }
    },
    videoPlayCallback(e) {
        const el = $(e.target)
        const index = el.data('index')
        const isClone = el.hasClass('clone');
        const teamIndex = el.data('team_index')
        console.log({ event: e.type, teamIndex, index, isClone })
    },
    videoEndCallbackFunc(nextVideo, index, teamIndex, el) {
        clearTimeout(this.timerList[teamIndex])
        if (nextVideo.length) {
            this.timerList[teamIndex] = setTimeout(() => {
                this.stopAllVideos(false, teamIndex);
                this.clearSiblingsVideos(el);
                nextVideo.fadeTo(0, 1)
                nextVideo.css({ "z-index": 5 });
                nextVideo[0].play();
                nextVideo[0].currentTime = 0;
            }, 50);
        }
    },
    videoEndCallback(e) {
        const el = $(e.target)
        const index = el.data('index')
        const teamIndex = el.data('team_index')
        switch (index) {
            case 0:
                this.children.eq(teamIndex).find('.bg').stop(true).fadeTo(250, 0);
                this.videoEndCallbackFunc(this.children.eq(teamIndex).find('video').eq(1), index, teamIndex, el);
                break;
            case 1:
                this.children.eq(teamIndex).find('.bg').stop(true).fadeTo(0, 1).fadeTo(500, 0);
                this.videoEndCallbackFunc(this.children.eq(teamIndex).find('video').eq(1), index, teamIndex, el);
                break;
            case 2:
                this.children.eq(teamIndex).find('.bg').stop(true).fadeTo(0, 1).fadeTo(0, 0);
                this.videoEndCallbackFunc(this.children.eq(teamIndex).find('video').eq(3), index, teamIndex, el);
                break;
            case 3:
                this.children.eq(teamIndex).find('.bg').stop(true).fadeTo(0, 1).fadeTo(500, 0);
                this.videoEndCallbackFunc(this.children.eq(teamIndex).find('video').eq(3), index, teamIndex, el);
                break;
            case 4:
                this.children.eq(teamIndex).find('.bg').stop(true).fadeTo(0, 1).fadeTo(500, 0);
                this.videoEndCallbackFunc(this.children.eq(teamIndex).find('video').eq(1), index, teamIndex, el);
                break;
            default:
                break;
        }
    },
    clearSiblingsVideos(el) {
        el.siblings(":not(.bg)").add(el).css({ "z-index": '' }).fadeTo(0, 0);
    }
}

// $(document).ready(function () {
//     if ($("#servicesVideos").length && window.innerWidth > 991) servicesVideos.init();
// });

$(window).load(function () {
    if ($("#servicesVideos").length && window.innerWidth > 991) {
        // servicesVideos.playAllVideos(true);
        // setTimeout(() => {
        // servicesVideos.stopAllVideos(true)
        servicesVideos.animations.firstIn.call(servicesVideos)
        // }, 100);
    };
})


const packageVideos = {
    timerList: new Array(20),
    children: null,
    videoEndCallback(e) {
        const el = $(e.target)
        const index = el.data('index')
        const characterIndex = el.data('character_index')
        // console.log('this.children', this.children, 'characterIndex', characterIndex, 'index', index)
        switch (index) {
            case 0:
                this.children.eq(characterIndex).find('.bg').stop(true).fadeTo(250, 0);
                this.videoEndCallbackFunc(this.children.eq(characterIndex).find('video').eq(1), characterIndex);
                break;
            case 1:
                this.children.eq(characterIndex).find('.bg').stop(true).fadeTo(0, 1).fadeTo(250, 0);
                this.videoEndCallbackFunc(this.children.eq(characterIndex).find('video').eq(1), characterIndex);
                break;
            case 2:
                el.parent().addClass("hide")
                break;
            default:
                break;
        }
    },
    videoEndCallbackFunc(nextVideo, teamIndex) {
        clearTimeout(this.timerList[teamIndex])
        if (nextVideo.length) {
            this.timerList[teamIndex] = setTimeout(() => {
                this.stopAllVideos(false, teamIndex);
                this.clearSiblingsVideos(nextVideo);
                nextVideo.fadeTo(0, 1)
                nextVideo.css({ "z-index": 5 });
                nextVideo[0].play();
                nextVideo[0].currentTime = 0;
            }, 50);
        }
    },
    updateChildren(planning = false) {
        // console.log('updateChildren, showAddPlanning', order.showAddPlanning)
        this.children = $(".stepPackagesRow").filter(!planning ? ".first" : ".second").find('.personalBlockColumn');
        // this.children = $(".stepPackagesRow:visible").find('.personalBlockColumn');
        // this.children = $('.stepPackagesRow .personalBlockColumn');
        this.addEvents();
    },
    removeEvents() {
        const thise = this;
        this.children.find("video").each(function () {
            $(this).off('ended', thise.videoEndCallback.bind(thise));
        });
    },
    addEvents() {
        const thise = this;
        this.children.find("video").each(function () {
            $(this).on('ended', thise.videoEndCallback.bind(thise));
        });
    },
    init() {
        this.children = $(".personalBlockColumn");
        // this.updateChildren();
        // this.playAllVideos(true)
    },
    stopAllVideos(all = false, index = 0) {
        const team = all ? this.children : this.children.eq(index)
        team.find("video").each(function () {
            $(this)[0].pause();
            $(this)[0].currentTime = 0;
        })
    },
    playAllVideos(all = false, index = 0) {
        const team = all ? this.children : this.children.eq(index)
        team.find("video").each(function () {
            $(this)[0].play();
            $(this)[0].currentTime = 0;
        })
    },
    animations: {
        firstIn() {
            this.stopAllVideos(true);
            this.children.find("video:first-child").each(function () {
                $(this).fadeTo(0, 0).fadeTo(1000, 1)
                $(this)[0].play();
            });
        },
        in(index = 0) {
            this.children.eq(index).find('.bg').stop(true).fadeTo(0, 1).fadeTo(250, 0);
            const el = this.children.eq(index).find("video[data-index=0]");
            el.parent().removeClass("hide")
            this.videoEndCallbackFunc(el, index)
        },
        out(index = 0) {
            this.children.eq(index).find('.bg').stop(true).fadeTo(0, 1).fadeTo(250, 0);
            const el = this.children.eq(index).find("video[data-index=2]");
            this.videoEndCallbackFunc(el, index)
            clearTimeout(this.timerList[10 + index])
            this.timerList[10 + index] = setTimeout(() => {
                el.fadeTo(1000, 0)
            }, 1000);
        }
    },
    clearSiblingsVideos(el, all = false) {
        if (all) {
            this.children.find(":not(.bg)").css({ "z-index": '' }).fadeTo(0, 0);
        } else {
            el.siblings(":not(.bg)").add(el).css({ "z-index": '' }).fadeTo(0, 0);
        }
    }
}
