class InfiniteParallax {
    constructor(scrollClass, disabled = false) {
        this.tweenIter = 0;
        this.tween = new TimelineLite();
        this.tweenSet = new TimelineLite();
        this.parent = $(".sect-main-3");
        this.divs = this.parent.find(".b-img");
        this.time = (() => {
            if (mobile) {
                if (isIphone()) return 0.5;
                if (isIpad) return 0.5;
                return 0.001;
            }
            return 10
        })();
        this.timeClone = this.time;
        this.mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        // this.ease = Power3.easeOut;
        this.ease = Expo.easeOut;
        // this.ease = "power1.out";
        this.disabled = disabled;
        this.mainInfo = this.parent.find(".gp-1-info-t");
        this.absoluteGrid = this.parent.find(".absoluteGrid");
        this.scrollClass = scrollClass;
        this.firstDisabled = false;
        this.timer = undefined;
        this.needReset = false;
        this.mainInfoTitle = this.mainInfo.find(".title");
        this.mainInfoDescription = this.mainInfo.find(".description");
        this.divsSlice1 = this.divs.slice(3, this.divs.length).filter(":nth-child(6n + 3)");
        this.divsSlice2 = this.divs.slice(0, this.divs.length / 4).filter(":even");
        this.divsSlice3 = this.divs.slice(0, this.divs.length * 0.74).filter(":nth-child(8n + 1)");
        this.divsSliceMain = $(this.divs).not(this.divsSlice1).not(this.divsSlice2);
        this.init();
    }

    onMouseMove(e) {
        if (this.disabled) return false;
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
        this.move();
    };

    resetMouse(move = false, hard = false) {
        this.mouse.x = window.innerWidth / 2;
        this.mouse.y = window.innerHeight / 2;
        this.shift = { x: 0, y: 0 };
        if (move) {
            const time = this.time;
            if (hard) this.time = 0.001;
            this.move();
            this.time = time;
        }
        this.needReset = false;
    };

    onTouchMove(e) {
        if (this.disabled) return false;
        if (e.touches.length > 0) {
            this.mouse.x = e.touches[0].clientX;
            this.mouse.y = e.touches[0].clientY;
        };
        // this.move();
        this.touchMove(e);
    };

    resize() {
        this.absoluteGrid.css("padding-left", '');
        this.absoluteGrid.css("padding-right", '');
        this.absoluteGrid.css("margin-left", '');
        this.absoluteGridWidth = this.absoluteGrid.innerWidth();
        this.absoluteGridHeight = this.absoluteGrid.innerHeight();
        this.absoluteGridMiddleWidth = (this.absoluteGridWidth / 2 - (this.mainInfo.position().left + this.mainInfo.innerWidth() / 2));
        if ((isIOS || isMacLike) && window.innerWidth > 991 && isSafari && mainPage) {
            this.absoluteGrid.css("margin-left", -this.absoluteGridMiddleWidth / 2);
        }else {
            this.absoluteGrid.css("padding-left", this.absoluteGridMiddleWidth);
        }
        // 
        this.maxShiftX = Math.abs(this.scrollClass.gridSize.width - window.innerWidth) / 2 + 150;
        this.maxShiftY = Math.abs(this.scrollClass.gridSize.height - window.innerHeight) - 100;
        this.defaultShift = {
            x: -this.absoluteGridWidth / 2,
            y: -this.absoluteGridHeight / 2 + this.scrollClass.sizeImage.margin / 2
        };
        this.tempShift = { ...this.defaultShift };
    }

    move() {
        this.needReset = true;
        this.moveMainParallax();
        // this.moveSmallParallax();
    };

    touchMove(e) {
        this.touchMoveMainParallax(e);
        this.touchSmallParallax();
    };

    touchMoveMainParallax(e) {
        this.tween.clear();
        const { x: currentX, y: currentY } = this.getSwipeOffset(e);
        const { x: tsX, y: tsY } = this.touchstart;

        const shift = {
            x: (currentX - tsX),
            y: (currentY - tsY),
        };

        let newShiftX = shift.x * (isIphone() ? 1.2 : 1);
        let newShiftY = shift.y * (isIphone() ? 1.2 : 1);

        if (this.tempShift.y + newShiftY > this.defaultShift.y + this.maxShiftY) { // top border
            newShiftY = this.defaultShift.y + this.maxShiftY - this.tempShift.y
        };
        if (this.tempShift.y + newShiftY < this.defaultShift.y - this.maxShiftY) { // bottom border
            newShiftY = -Math.abs(this.defaultShift.y) - this.maxShiftY - this.tempShift.y
        };
        if (Math.abs(this.tempShift.x) - newShiftX < Math.abs(this.defaultShift.x) - this.maxShiftX) { // left border
            newShiftX = -Math.abs(this.defaultShift.x) + this.maxShiftX + Math.abs(this.tempShift.x)
        };
        if (Math.abs(this.tempShift.x) - newShiftX > Math.abs(this.defaultShift.x) + this.maxShiftX) { // right border
            newShiftX = -Math.abs(this.defaultShift.x) - this.maxShiftX + Math.abs(this.tempShift.x)
        };

        this.tween.to(this.shift, this.time, {
            x: newShiftX,
            y: newShiftY,
            onUpdate: () => {
                const x = this.tempShift.x + this.shift.x;
                const y = this.tempShift.y + this.shift.y;
                this.currentShift = {
                    x: this.defaultShift.x - x,
                    y: y - this.defaultShift.y
                };
                TweenMax.set(this.absoluteGrid, {
                    x: x,
                    y: y,
                });
                this.smallParallaxDoSet(this.currentShift.x / 30, this.currentShift.y / 30)
            },
            ease: this.ease,
        });
    }

    touchSmallParallax() {
        // const shiftX = this.currentShift.x / 20;
        // const shiftY = this.currentShift.y / 20;
        // console.log({ shiftX, shiftY })
        // this.smallParallaxDo(shiftX, shiftY);
    };

    moveMainParallax() {
        const percentShiftX = (this.mouse.x - window.innerWidth / 2) / window.innerWidth * 2;
        const maxShiftX = -Math.abs(this.scrollClass.gridSize.width - window.innerWidth) / 2 - 300;
        const percentShiftY = (this.mouse.y - window.innerHeight / 2) / window.innerHeight * 2;
        const maxShiftY = Math.abs(this.scrollClass.gridSize.height - window.innerHeight) + 150;

        this.tween.clear();
        clearTimeout(this.timer)
        this.tween.to(this.tempShift, this.time, {
            x: this.defaultShift.x + maxShiftX * percentShiftX,
            y: this.defaultShift.y - maxShiftY * percentShiftY,
            ease: this.ease,
            onUpdate: () => {
                const shiftX = (this.tempShift.x - this.defaultShift.x) / 20;
                const shiftY = (this.tempShift.y - this.defaultShift.y) / 20;
                if (!(++this.tweenIter % 3)) this.smallParallaxDoSet(shiftX, shiftY)
                TweenMax.set(this.absoluteGrid, {
                    x: this.tempShift.x,
                    y: this.tempShift.y,
                })
            },
            onComplete: () => {
                clearTimeout(this.timer)
                if (this.needReset) {
                    this.timer = setTimeout(() => {
                        clearTimeout(this.timer)
                        this.resetMouse(true)
                    }, 1000);
                }
            }
        });
    };

    smallParallaxDoSet(shiftX, shiftY) {
        // TweenMax.set(this.divsSliceMain, {
        //     // z: 15,
        //     x: shiftX * 0.5,
        //     y: shiftY / 2 * 1.2,
        //     // ease: this.ease,
        // });
        const thise = this;
        this.divsSlice1.each(function (index) {
            const shiftXTemp = shiftX * 3;
            const shiftYTemp = shiftY * 2.5;
            TweenMax.set($(this)[0], {
                z: isFirefox ? 20 : 100,
                x: shiftXTemp * (index + 1) / thise.divsSlice1.length,
                y: shiftYTemp * (index + 1) / thise.divsSlice1.length,
            });
        })

        TweenMax.set(this.divsSlice2, {
            z: 20,
            x: shiftX * 1.5,
            y: shiftY * 1,
            // ease: this.ease,
        });
        TweenMax.set(this.divsSlice3, {
            z: 10,
            x: shiftX * 1.5,
            y: shiftY * 0.8,
            // ease: this.ease,
        });
        // TweenMax.set(this.mainInfoTitle, {
        //     x: (shiftX * 0.3),
        //     // ease: this.ease,
        // });
        // TweenMax.set(this.mainInfoDescription, {
        //     x: parseInt(shiftX * 0.2),
        //     // ease: this.ease,
        // });
    };

    getSwipeOffset(event) {
        let offset, offsetX, offsetY;
        if (event && (event.type == "touchstart" || event.type == "touchmove" || event.type == "touchend")) {
            const touch =
                event.changedTouches[0] ||
                event.originalEvent.touches[0] ||
                event.originalEvent.changedTouches[0];
            offsetX = touch.clientX;
            offsetY = touch.clientY;

        } else {
            //если нажата кнопка мышки:
            offsetX = event.clientX;
            offsetY = event.clientY;
        }
        return {
            y: offsetY,
            x: offsetX,
        };
    };

    init() {
        this.currentShift = { x: 0, y: 0 };
        if (mobile) {
            window.on("touchmove.InfiniteParallax", this.onTouchMove.bind(this));
        } else {
            window.on("mousemove.InfiniteParallax", this.onMouseMove.bind(this));
        }
        window.on("resize.parallaxResize", this.resize.bind(this));
        document.addEventListener('touchmove', (e) => {
            this.touchMoveEvent = e;
        });
        document.addEventListener('touchstart', (e) => {
            this.tween.clear();
            this.touchstart = this.getSwipeOffset(e)
            this.tempShift.x += this.shift.x;
            this.tempShift.y += this.shift.y;
            this.activeTouch = true;
            this.shift.x = 0;
            this.shift.y = 0;
        });
        document.addEventListener('touchend', (e) => {
            this.touchstart = this.getSwipeOffset(e)
            this.activeTouch = false;
        }, false);

        this.resize();
        this.time = 0;
        this.resetMouse(true);
        setTimeout(() => {
            if (!this.disabled) {
                this.firstDisabled = true;
                if (!this.scrollClass.infiniteScroll.hasClass("hide")) {
                    this.disabled = false;
                }
            }
            // this.time = this.timeClone ;
            TweenMax.fromTo(this, 2, { time: mobile ? 10 : 35 }, { time: this.timeClone })
        }, mobile ? 1000 : 3000);
        this.smallParallaxDoSet(0, 0)
    };

    destroy() {
        window.off("mousemove.InfiniteParallax");
        window.off("touchmove.InfiniteParallax");
        window.off("resize.parallaxResize");
    };

    disable() {
        this.disabled = true;
    };

    enable() {
        this.disabled = false;
    };
}