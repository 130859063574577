
let cursor;
let cursorFirstMove = false;
let cursorPos = { x: 0, y: 0 };
const customCursor = !false;

function onDocumentMouseMove(event) {
    cursorPos.x = event.clientX;
    cursorPos.y = event.clientY;
    // console.log('x', cursorPos.x, 'y', cursorPos.y)
    if (!cursorFirstMove) {
        cursorFirstMove = true;
        $("#cursor").show(0).fadeTo(250, 1);
        TweenMax.set(cursor, {
            left: event.clientX,
            top: event.clientY,
        });
    };
    TweenMax.to(cursor, 0.1, {
        left: event.clientX,
        top: event.clientY,
        ease: Power2.easeOut
    });
    // TweenMax.to('#cursorShadow', 1, {
    //     left: event.clientX,
    //     top: event.clientY,
    //     ease: Power2.easeOut
    // });
};

const cursorElems = [
    'a',
    '.btn-menu',
    'span.place-holder',
    '.ic-date',
    '.chosen-results',
    '.blog-article-head-right span',
    '.blog-search-btn',
    'button',
    '.swiper-button-next',
    '.swiper-button-prev',
    '.btn-play',
    '.playIcon',
    '.flatpickr-calendar',
    'ul.swiper-menu li',
    '.swiper-pagination-bullet',
    '.tooltip',
    '.packs-slider-down > span',

    'a:not(.default)',
    '.teamListItem',
    '.portfolioItemContent .rect',
    '.popup_block .close',
    '.hoderParallax',
    '.hideLayer',
    '.packagesListItem',
    '.headerSteps li',
    '#selectDate',
    '.daysListItem',
    '.yearsListItem',
    '.monthsListItem',
    '.addonsItem',
    '.packagesListItem',
    '.packagesCard',
    '.stepAddons .scrollElem',
    '.payMethods label',
    // '.teamListImagesItem .image'
    '.sect-bottom-scroll > span',
];

const cursorLogos = [
    '.drag'
];

const shiftHoverEl = (e, target) => {
    const shift = 14;
    const x = e.offsetX;
    const y = e.offsetY;
    const width = target.innerWidth();
    const height = target.innerHeight();
    TweenMax.to(target, 0.5, {
        x: (x / width - 0.5) * shift,
        y: (y / height - 0.5) * shift,
        ease: Power1.out
    });
};

$(document).ready(function () {
    if (!mobile /*&& !tablet*/ && !isIpad && customCursor) {
        cursor = $("#cursor");
        if (!cursor.length) {
            $("body").append('<div id="cursor" class="is-big"></div>');
            cursor = $("#cursor");
        };
        document.addEventListener("mousemove", onDocumentMouseMove, false);
        $(document).on('mouseenter', cursorElems.join(', '), function (e) {
            $("#cursor").addClass("hover");
        });
        $(document).on('mouseleave', cursorElems.join(', '), function (e) {
            $("#cursor").removeClass("hover");
        });

        $(document).on('mouseenter', cursorLogos.join(', '), function (e) {
            if ($(e.target).closest(".awardsWrap").innerWidth() > window.innerWidth) {
                $("#cursor").addClass("hover2");
            }
        });
        $(document).on('mouseleave', cursorLogos.join(', '), function (e) {
            $("#cursor").removeClass("hover2");
        });

    };
});