const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
const isIpad = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
const isIOS = isIpad || navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false;
const initDesktop = window.innerWidth > 991;

const mobile = device.mobile() || device.tablet() || isIpad;
if (mobile) $('body, html').addClass("device")

if (isIOS) $("body, html").addClass("isIOS");
if (isMacLike) $("body, html").addClass("isMacLike");
if (isSafari) $("body, html").addClass("isSafari");
if (isIpad) $("body, html").addClass("isIpad");
if (isFirefox) $("body, html").addClass("isFirefox");
const mainPage = $(".mainPage").length ? true : false;



function iPhoneVersion() {
    var iHeight = window.screen.height;
    var iWidth = window.screen.width;

    if (iWidth === 414 && iHeight === 896) {
        return "Xmax-Xr";
    }
    else if (iWidth === 375 && iHeight === 812) {
        return "X-Xs";
    }
    else if (iWidth === 320 && iHeight === 480) {
        return "4";
    }
    else if (iWidth === 375 && iHeight === 667) {
        return "6";
    }
    else if (iWidth === 414 && iHeight === 736) {
        return "6+";
    }
    else if (iWidth === 320 && iHeight === 568) {
        return "5";
    }
    else if (iHeight <= 480) {
        return "2-3";
    }
    return false;
};

// check_resize.js
function get_responsive(responsive, window_width = $(window).innerWidth()) {
    let prev = false;
    let i;
    let responsiveArr = responsive.slice();
    responsiveArr.push(99999999)
    $.each(responsiveArr, function (index, element) {
        if (prev !== false && window_width <= responsiveArr[index] && window_width > prev) {
            i = index - 1;
            return false;
        } else {
            prev = element;
        }
    });
    // console.log('responsive = ' + responsiveArr);
    // console.log(responsive[i]);
    return responsiveArr[i];
};
let responsiveNow;
const responsiveSteps = [0, 991];
function check_resize() {
    responsiveNow = get_responsive(responsiveSteps);
    check_resizeDo(responsiveNow);
    $(window).resize(function () {
        if (responsiveNow != get_responsive(responsiveSteps)) {
            responsiveNow = get_responsive(responsiveSteps);
            check_resizeDo(responsiveNow);
        }
    });
}
function check_resizeDo(responsiveNow) {
    switch (responsiveNow) {
        case 0:
            if (windowLoad) move_to_tablet();
            break;
        case 991:
            if (windowLoad) move_to_desktop();
            break;
        default:
            break;
    }
}

// check_resize.js

function isIphone() {
    return !!navigator.userAgent.match(/iPhone/i);
}

if (isIphone()) {
    $("body").addClass("iphone_model_" + iPhoneVersion());
}

let windowLoad = false;
let preloaderDone = false;

const showPreloader = () => {
    const preloader = $("#preloader");
    if (preloader.length) {
        preloader.addClass("start");
        setTimeout(() => {
            preloaderDone = true;
            if (windowLoad) {
                hidePreloader();
            } else {
                preloader.addClass('pulse');
            }
        }, 3000);
    } else {
        if ($(".pagescroll").length && !$('.infiniteScroll').length) startPagescroll();
    }
};

const hidePreloader = () => {
    startPagescroll();
    $("#preloader").fadeOut(500, function () {
        $(this).hide();
    });

    $(".main-1").find(".main-1-text, .sect-bottom").fadeTo(0, 1)
    setTimeout(() => {
        pagescroll.setInProgress(false);
        //     videoPlayer.video.currentTime = 5;
        myInfiniteScroll.parallax.disable()
        myInfiniteScroll.parallax.resetMouse(true, true);
    }, 750);
};

const startPagescroll = () => {
    pagescroll.setInProgress(true);
    screenCallbackAfter(pagescroll.current, false, () => { pagescroll.setInProgress(false) });
};

const screenCallbackBefore = (index, customMove, direction, donefunc = () => { }) => {
    animations.beforeSlide = index
    $("html, body").addClass("disablePage");
    let nextEl = $(".section.active")[direction == 'moveUp' ? 'prev' : 'next']();
    if ($(".section.active").hasClass("infiniteScroll") && animations.screen3.fixForSafari) {
        if (direction == 'moveUp') nextEl = $(".sect-main-2");
        if (direction !== 'moveUp') nextEl = $(".sect-main-4");
    }
    const screenName = detectCurrentScreenName();
    animations[screenName].out(donefunc, nextEl);
    // 
    const activeSection = $(`.section[data-index=${index}]`);
    setTimeout(() => {
        if (activeSection.hasClass("sect-main-5")) {
            $('header').removeClass('dark-color');
        }
    }, 750);

    // setTimeout(donefunc, 1500);
};


const detectCurrentScreenName = () => {
    const activeEl = $(".section.active");
    let name;
    if (activeEl.hasClass("sect-main-1")) {
        name = 'screen1';
    } if (activeEl.hasClass("sect-main-2")) {
        name = 'screen2';
    } else if (activeEl.hasClass("sect-main-3")) {
        name = 'screen3';
    } else if (activeEl.hasClass("sect-main-4")) {
        name = 'screen4';
    } else if (activeEl.hasClass("sect-main-5")) {
        name = 'screen5';
    } else if (activeEl.hasClass("screen6")) {
        name = 'screen6';
    } else {
        name = 'screen1';
    };
    return name
}

const screenCallbackAfter = (index, customMove, donefunc = () => { }) => {
    // console.log('screenCallbackAfter', index, customMove, donefunc)
    // donefunc()
    animations.activeSlide = index
    const activeEl = $(".onepage-wrapper .section.active");
    const screenName = detectCurrentScreenName();
    animations[screenName].in(donefunc);
    if (index == 1) activeEl.fadeTo(250, 1)
    // 
    if (!activeEl.hasClass("sect-main-1") && !activeEl.hasClass("sect-main-5")) {
        $('header').addClass('dark-color');
    } else {
        $('header').removeClass('dark-color');
    }
    if (activeEl.hasClass("dark-block")) {
        $('header').removeClass('dark-color');
    }
    setTimeout(() => { $("html, body").removeClass("disablePage") }, 450);
    $("#pagination .dot").removeClass("active").eq(index - 1).addClass("active")
    $(".pagination").removeClass("disabled");
};

function animateFromTo(elem, time, from, to, delay = 0) {
    TweenMax.fromTo(elem, time, from, to).delay(delay);
};

function rnd(min, max) {
    var rand = min - 0.5 + Math.random() * (max - min + 1);
    rand = Math.round(rand);
    return rand;
};

var events = {
    on(event, cb, opts) {
        if (!this.namespaces) // save the namespaces on the DOM element itself
            this.namespaces = {};

        this.namespaces[event] = cb;
        var options = opts || false;

        this.addEventListener(event.split('.')[0], cb, options);
        return this;
    },
    off(event) {
        this.removeEventListener(event.split('.')[0], this.namespaces[event]);
        delete this.namespaces[event];
        return this;
    }
}

// Extend the DOM with these above custom methods
window.on = Element.prototype.on = events.on;
window.off = Element.prototype.off = events.off;

function create_wave(stop = false) {
    $(".wave:not(.created)").each(function () {
        let default_html;
        if ($(this).data('html') == undefined) {
            default_html = $(this).html();
            $(this).attr('data-html', default_html);
        } else {
            default_html = $(this).data('html');
        };
        $(this).html(default_html);

        var lineHeight = parseInt($(this).css("line-height"));
        var tag = $(this)[0].tagName;
        var boldArr = [];
        $(this).find("b, span").each(function () {
            $(this).text().split(' ').forEach(element => {
                boldArr.push(element);
            });
        });

        $("body").append(`<${tag} class='${$(this).attr("class")} line_separator' id='line_separator' style='
            left: -2000px;
            top: -2000px;
            margin: 0!important;
            padding: ${$(this).css('padding')};
            display: block;
            overflow: hidden;    
            width: ${$(this).innerWidth()}px; 
            height: ${lineHeight}px;
            font-size: ${$(this).css("font-size")};
            font-weight: ${$(this).css("font-weight")};
            font-family: ${$(this).css("font-family")};
            line-height: ${lineHeight}px;
        '></${tag}>`);

        if (tag == "P") {
            $("#line_separator").html("<span class='myText'></span>")
        } else {
            $("#line_separator").html("<div class='myText'></div>")
        }
        var text = $(this).text().replace(/^\s*/, '').replace(/\s*$/, '');
        var arr_words = text.split(" ");
        let lines = [];
        $.each(arr_words, function (index, value) {
            if (value == '') return true;
            var scroll_height_before = $('#line_separator .myText').innerHeight();
            var text = $('#line_separator .myText').text();
            $('#line_separator .myText').text(text + value + ' ')
            var scroll_height_after = $('#line_separator .myText').innerHeight();
            if (scroll_height_after > lineHeight) {
                lines.push(text);
                $('#line_separator .myText').text(value + ' ');
                if (index == arr_words.length - 1) {
                    lines.push(value);
                };
            } else {
                if (index == arr_words.length - 1) {
                    lines.push(text + value);
                };
                return true;
            };
        });
        $.each(lines, function (index, value) {
            let class_span;
            var line_index = index;
            var mass_words = value.split(" ");
            var index_char = 0;
            $.each(mass_words, function (index, value) {
                if (value == '') return true;
                var mass = value.split(" ");
                var new_text = "";
                for (var i = 0; i < mass.length; i++) {
                    if (mass[i] == " ") {
                        mass[i] = '&nbsp;'
                    };
                    new_text += mass[i];
                    index_char++;
                };
                // если слово в спане
                jQuery.inArray(value, boldArr) !== -1 ? class_span = 'bold' : class_span = '';
                mass_words[index] = `<div class='word ${class_span}'>${new_text} </div>`;
            });
            lines[index] = `<div class='textLine'>${mass_words.join(' ')}</div>`;
        });
        $(this).html(`<div class='rows'>${lines.join('')}</div>`);
        $("#line_separator").remove();
        $(this).addClass("created");
    });
};

class CustomParallax {
    constructor() {
        this.parent = null;
        this.divs = null;
        this.time = 1.5;
        this.mouse = { x: 0, y: 0 };
        this.ease = Power3.easeOut;
        this.disabled = true;
        this.init();
    };

    onMouseMove(e) {
        if (this.disabled) return false;
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
        this.move();
    };

    resetMouse() {
        this.mouse.x = window.innerWidth / 2;
        this.mouse.y = window.innerHeight / 2;
    };

    onTouchMove(e) {
        if (this.disabled) return false;
        if (e.touches.length > 0) {
            this.mouse.x = e.touches[0].clientX;
            this.mouse.y = e.touches[0].clientY;
        };
        this.move();
    };

    move() {
        const shiftX = (this.mouse.x - window.innerWidth / 2) / 17;
        const shiftY = (this.mouse.y - window.innerHeight / 2) / 15;
        TweenMax.to(this.divs.slice(0, this.divs.length / 2).filter(":even"), this.time, {
            x: shiftX / 2,
            y: shiftY / 2,
            ease: this.ease,
        });
        TweenMax.to(this.divs.slice(this.divs.length / 2).filter(":odd"), this.time, {
            x: shiftX / 2 * 1.2,
            y: shiftY / 2 * 1.2,
            ease: this.ease,
        });
        TweenMax.to(this.divs.slice(0, this.divs.length / 2).filter(":odd"), this.time, {
            x: shiftX * 1.1,
            y: shiftY * 1.1,
            ease: this.ease,
        });
        TweenMax.to(this.divs.slice(this.divs.length / 2).filter(":even"), this.time, {
            x: shiftX * 1.45,
            y: shiftY * 1.45,
            ease: this.ease,
        });
    };

    init() {
        this.parent = $(".sect-main-3");
        this.divs = this.parent.find(".b-img");
        // this.divs.wrapInner('<div class="wrap"></div>')
        window.on("mousemove.parallax", this.onMouseMove.bind(this));
        window.on("touchmove.parallax", this.onTouchMove.bind(this));
    };

    destroy() {
        window.off("mousemove.parallax");
        window.off("touchmove.parallax");
    };

    disable() {
        this.disabled = true;
    };

    enable() {
        this.disabled = false;
    };

};

$(document).ready(function () {
    if ($("#preloader").length) $('body').addClass('withPreloader');
    // if ($(".pagescroll").length) $('body').addClass('mainPage');
    $(".formSubmit, #preloader").each(function () {
        const preloader = $(this).attr("id") == 'preloader';
        const delay = (index) => {
            if (preloader) return 500 + index * 100;
            return 400 + index * 50
        };
        const span = $(this).find('span');
        const html = span.text().split('').reduce((sum, current, index) => {
            return sum + `<b style='transition-delay: ${delay(index)}ms'>${current}</b>`
        }, '');
        span.html(html);
    });
    if (window.innerWidth > 991) setTimeout(showPreloader, 250);
    if ($("#video").length) videoPlayer.init();
    $(".header-logo path").each(function (index) {
        $(this).css('transition-delay', 500 + index * 100 + 'ms')
    });
    // 
    $(".sect-main-3 .b-img img, .sect-main-2 .b-img img, .photo-item img").wrap('<div class="imgWrap"></div>');
    $(".sect-main-2 .b-img").addClass("default");
    $(".sect-main-3 .b-img, .sect-main-2 .b-img, .photo-item").each(function () {
        $(this).addClass($(this).innerWidth() > $(this).innerHeight() ? 'landscape' : 'portrait');
        $(this).wrapInner('<div class="wrap"></div>').addClass("louversImage");
    });

    // $("a.formSubmit").addClass("wow");
    $(".photo-item.louversImage").addClass("dafaultFade wow");
    $(".photo-item.louversImage").addClass("done");
    // 
    if (window.innerWidth <= 991) move_to_tablet();
    // if (window.innerWidth <= 991) createWow();
});

let moveParallax;
$(window).load(function () {
    check_resize();
    // if ($("#video").length && window.innerWidth <= 991) setTimeout(() => { videoPlayer.init() }, 1000);
    windowLoad = true;
    if (preloaderDone) hidePreloader();
    if ($(".sect-main-3:not(.infiniteScroll)").length) moveParallax = new CustomParallax();
    create_wave();
    $("header").fadeTo(250, 1);
    if ($(".gyp-1").length) animations.galleryScreen1.in()
    // 


    if ($(".awards-box").length) {
        awardsLoop.init();
    }
    if (window.innerWidth > 991) createWow();
});
const showMoreBoxPhoto = (e, top) => {
    e.stopPropagation();
    e.preventDefault();
    const eles = $(".photo-box .photo-item.mob-hidden");
    eles.addClass("mob-show").removeClass("show clear").addClass("transition0s")
    setTimeout(() => {
        eles.removeClass("transition0s")
        $('body, html').scrollTop(top + 1)
    }, 50);
};

const videoPlayer = {
    player: null,
    video: null,
    circle: null,
    duration: null,
    playIcon: null,
    inited: false,
    options: {
        id: 'https://vimeo.com/485367276/180551a095',
        width: 100,
        height: 100,
        loop: true,
        transparent: true,
        autopause: false,
        autoplay: true,
        background: true,
        controls: false,
        muted: true,
        playsinline: true,
    },
    init: function (play = false) {
        this.video = document.getElementById('video');
        this.circle = $('.playIcon .circle.current');
        this.playIcon = $('.playIcon');
        this.playIcon.click(() => {
            this.player.getPaused().then((paused) => { paused ? this.play() : this.pause() })
        })
        this.player = new Vimeo.Player(this.video, this.options);
        this.player.getDuration().then((duration) => {
            this.duration = duration
        })
        this.player.on('play', () => {
            $(this.video).fadeTo(0, 1)
        });
        this.player.on('timeupdate', ({ seconds, percent, duration }) => {
            this.currentTime = seconds;
            this.duration = duration;
            const dashoffset = 125 + (125 * percent) % 250;
            this.circle.css("stroke-dasharray", dashoffset);
        });
        this.inited = true;
        this.responsive();
        window.addEventListener("resize", this.responsive, false)
    },
    play: function () {
        this.player.play()
        this.responsive();
        this.playIcon.removeClass("paused");
    },
    pause: function () {
        this.player.pause()
        this.playIcon.addClass("paused")
    },
    responsive: function () {
        var $box = $(this.video);
        $box.removeAttr("style");
        var height = $box.height();
        var width = $box.width();
        var new_height = width / 1.78;
        if (new_height > height) {
            $box.css({
                width: width,
                height: new_height,
                top: -(new_height / 2 - height / 2),
                left: -1,
            });
        } else {
            var new_width = height * 1.78;
            $box.css({
                width: new_width,
                height: height,
                top: '0',
                left: -(new_width / 2 - width / 2) - 1
            });
        }
    }
};

const animations = {
    activeSlide: 1,
    goTo: function (index = 1) {
        const screenName = detectCurrentScreenName();
        this[screenName].out(() => {
            scrolDownAccess = true;
            pagescroll.moveTo(index, true)
        });
    },
    header: {
        time: 0.7,
        fadeDelay: 2,
        clear: function () {
            $(".header-logo").removeClass("anim show")
            $('header').removeClass("headerWithBG")
        },
        default: function (deleyCoef = 1) {
            const thise = this;
            setTimeout(() => { $(".header-logo").removeClass('transition0s').addClass("show") }, 150);
            animateFromTo('.header-menu', thise.time * 0.7,
                { y: "-100%", opacity: 0 },
                {
                    y: "0%", opacity: 1,
                    onComplete: () => {
                        $("header").addClass("fadeDone");
                        $('header').addClass("headerWithBG")
                    }
                }, this.fadeDelay * 0.7 * deleyCoef);
        },
        in: function (deleyCoef = 1) {
            $(".header-logo").addClass("transition0s");
            this.clear();
            $(".header-logo").addClass("anim");
            this.default(deleyCoef);
        },
        check: function (event, parent, out) {
            if (!$('header').hasClass("fadeDone")) {
                // if ($(parent).data('index') == 1 && !out) {
                this[event]();
                // }
            };
        }
    },
    galleryScreen1: {
        parent: '.gyp-1',
        time: 0.7,
        fadeDelay: 0,
        default: function (out = true) {
            const thise = this;
            animations.defaultFadeWave(this.parent, out, this.time, 0)
            let delayBottomRow = $(this.parent + " .wave.title .textLine").length * 100 / 1000 + thise.time / 2 + thise.fadeDelay;
            animateFromTo(this.parent + ' .sect-bottom-scroll .textSpan > span', thise.time * 0.7,
                { y: "100%" },
                {
                    y: "0%",
                    onStart: () => {
                        setTimeout(() => {
                            $(this.parent + ' .btn-play').addClass("show");
                            $(this.parent + ' .sect-bottom-scroll').addClass("show");
                        }, thise.time * 0.7 * 0.7 * 1000);
                    }
                }, delayBottomRow);
        },
        in: function (done = () => { }) {
            const thise = this;
            $(".gyp-1 .gyp-1-text").fadeTo(0, 1);
            // $(".gyp-1-elipse").delay(1000).fadeIn(3000);
            this.default(false);
            // animations.header.in(0.5);
        },
    },
    screen1: {
        parent: '.sect-main-1',
        time: 0.7,
        // fadeDelay: 2,
        fadeDelay: 1,
        clear: function (out = true) {
            const thise = this;
            $(this.parent).find('.playIcon, .sect-bottom-scroll').removeClass('show');
            $(".sect-bottom-scroll").removeClass('pulse');
            // $(".header-logo").removeClass("anim show")
            animations.header.check('clear', this.parent);
            $('header').removeClass("headerWithBG")
        },
        default: function (out = true) {
            const thise = this;
            // setTimeout(() => { $(".header-logo").removeClass('transition0s').addClass("show") }, 150);
            animations.header.check('in', this.parent);
            animations.defaultFadeWave(this.parent, out, this.time, this.fadeDelay, 150);
            let delayBottomRow = $(this.parent + " .wave.title .textLine").length * 100 / 1000 + thise.time * 0.7 / 2 + thise.fadeDelay;
            animateFromTo(this.parent + ' .sect-bottom-scroll .textSpan > span', thise.time * 0.7,
                { y: "100%" },
                {
                    y: "0%",
                    onStart: () => {
                        setTimeout(() => {
                            $(this.parent + ' .sect-bottom-scroll').addClass("show");
                        }, thise.time * 0.7 * 0.5 * 1000);
                    }
                }, delayBottomRow);

            // right
            $(this.parent + " .waves > *").each(function (index) {
                animateFromTo($(this), thise.time * 0.5, {
                    y: out ? 0 : 15,
                    opacity: out ? 1 : 0,
                }, {
                    y: out ? -15 : 0,
                    opacity: out ? 0 : 1,
                    ease: out ? Power1.easeIn : Power1.easeOut
                }, index * 50 / 1000 + delayBottomRow);
            });
            setTimeout(() => {
                $(this.parent + " .playIcon").addClass('show');
            }, (delayBottomRow += 0.25) * 1000);
        },
        in: function (done = () => { }) {
            $("#pagination").removeClass("black");
            const thise = this;
            $(this.parent).find(">div").fadeTo(0, 1);
            $(this.parent + ' #video').fadeTo(750, 1)
            $(".main-1-elipse").fadeTo(0, 0).delay(2000).fadeTo(750, 1)
            // $(".header-logo").addClass("transition0s");
            $("header").removeClass("fadeDone");
            this.clear();
            this.default(false);
            videoPlayer.play();
            // $(".header-logo").addClass("anim");
            // animations.header.check('in', this.parent);
            TweenMax.fromTo('#pagination', 0.25, { x: 25, opacity: 0 }, { x: 0, opacity: 1 }).delay(2)
            pagescroll.setInProgress(false)
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
            animateFromTo(this.parent, 1, {}, {
                opacity: 0,
                ease: Power1.easeIn,
                onComplete: () => {
                    this.clear();
                    videoPlayer.pause();
                    $(".sect-bottom-scroll").addClass('pulse');
                    done();
                }
            })
        }
    },
    screen2: {
        parent: '.sect-main-2',
        time: 0.7,
        clear: function (out = true) {
            TweenMax.set(this.parent + " .b-img", { scale: 1 })
            TweenMax.set(this.parent + " .louversImage", { scale: 1, x: 0, y: 0 })
            TweenMax.set(this.parent + " .louversImage .wrap", { opacity: 1 })
        },
        default: function (out = true) {
            const thise = this;
            animations.defaultFadeWave(this.parent, out, this.time, 0);
        },
        in: function (done = () => { }) {
            $("#pagination").addClass("black");
            const thise = this;
            $(this.parent).find(">div").fadeTo(0, 1);
            $(this.parent + ' .main-2-info').fadeTo(0, 1);
            this.clear();
            this.default(false);
            animateFromTo(this.parent + " .b-img:nth-child(2)", thise.time * 1.3, { scale: 1.2, opacity: 0 }, { scale: 1, opacity: 1, ease: Power2.easeOut }, thise.time + (1 * 100 / 1000))
            $(this.parent + " .b-img").slice(2).add($(this.parent + " .b-img").slice(0, 1)).each(function (index) {
                animateFromTo($(this), thise.time * 2, { opacity: 0 }, { opacity: 1 }, (index * 120 + 500) / 1000 + (thise.time * 1.1 + (2 * 100 / 1000)))
            });
            if (animations.beforeSlide == 1) {
                animateFromTo(this.parent + " .sect-bottom", thise.time * 2, { opacity: 0 }, { opacity: 1 })
            };
            setTimeout(done, 1000);
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
            this.default(true);
            animateFromTo(this.parent + " .b-img .wrap", thise.time * 0.8, { opacity: 1 }, { opacity: 0, ease: Power2.easeIn })
            animateFromTo(this.parent + " .b-img:nth-child(2)", thise.time, {
                'transform-origin': '100% 100%',
                x: 0, scale: 1
            }, { x: 40, scale: 0.9, ease: Power2.easeIn })
            animateFromTo($(this.parent + " .b-img").slice(2).add($(this.parent + " .b-img").slice(0, 1)), thise.time,
                {
                    'transform-origin': '100% 0%',
                    y: 0,
                    // scale: 1
                },
                { y: -40, scale: 0.9, ease: Power2.easeIn })
            setTimeout(done, 1000);
        }
    },
    screen3: {
        fixForSafari: false,
        parent: '.sect-main-3',
        time: 0.7,
        parallaxTimer: null,
        clear: function (out = true) {
            if (isSafari) {
                $(".sect-main-2").after($(".infiniteScroll").removeClass("absolute"))
                this.fixForSafari = false;
            }
            clearTimeout(this.parallaxTimer);
            if (typeof moveParallax != 'undefined') moveParallax.disable();
            if (typeof moveParallax != 'undefined') moveParallax.resetMouse(true, true);
            TweenMax.set('.louversImage', { x: 0, y: 0 })
            TweenMax.set('.louversImage .wrap', { opacity: 1, y: 0 })
            $(this.parent + " .louversImage").removeClass("show out").addClass("transition0s").removeAttr('style').find('.wrap').removeAttr('style');
            animations.header.check('clear', this.parent, out);
        },
        default: function (out = true) {
            const thise = this;
            animations.header.check('default', this.parent, out);
            animations.defaultFadeWave(this.parent, out, this.time, 0);
        },
        in: function (done = () => { }) {
            if (isSafari && window.innerWidth > 991) {
                $(".bodyWrap").prepend($(".infiniteScroll").addClass("absolute"));
                this.fixForSafari = true;
            }
            $("#pagination").addClass("black");
            initScreen3ScrollEvents();
            const thise = this;
            $(this.parent).find(">div").fadeTo(0, 1);
            TweenMax.to(this.parent + ' .main-3-info', 0.5, { opacity: 1 }).delay(0.1)
            this.default(false);
            $(this.parent + " .main-3-info-links").removeClass("in start");
            TweenMax.set(this.parent + ' .main-3-info-links', { y: 0 })
            if (mobile) {
                animateFromTo('.infiniteScroll .scrollDown', thise.time * 1.5, {}, { opacity: 1, display: 'block' }, 0.2)
            }
            setTimeout(() => { $(this.parent + " .main-3-info-links").addClass("in") }, 4);
            setTimeout(() => { $(this.parent + " .main-3-info-links").addClass("start") }, 400);
            if (window.innerWidth > 991) {
                const path = $(this.parent + " .absoluteGrid").length ? this.parent + " .absoluteGrid .louversImage" : this.parent + " .louversImage";
                $(path).each(function (index) {
                    setTimeout(() => {
                        $(this).addClass("show");
                    }, index * 120 + 1000);
                });
                $(this.parent + " .louversImage").removeClass("transition0s show out");
                clearTimeout(this.parallaxTimer);
                let louversDelay = ($(this.parent + " .louversImage").length - 1) * 120;
                this.parallaxTimer = setTimeout(() => {
                    if (typeof myInfiniteScroll != 'undefined') {
                        myInfiniteScroll.parallax.enable();
                    }
                }, louversDelay);
            }
            animations.header.check('in', this.parent);
            if (myInfiniteScroll && myInfiniteScroll.parallax) {
                myInfiniteScroll.parallax.resetMouse(true, true);
                myInfiniteScroll.parallax.disable();
                setTimeout(() => {
                    myInfiniteScroll.parallax.enable();
                }, 3000);
            }
            setTimeout(done, 1000);
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
            if (myInfiniteScroll) myInfiniteScroll.parallax.disable();
            if (typeof moveParallax != 'undefined') moveParallax.disable();
            setTimeout(() => { this.default(true) }, 200);
            animateFromTo('.infiniteScroll .scrollDown', thise.time * 1.5, {}, { opacity: 0, display: 'none' }, 0.2)
            animateFromTo('.main-3-info', thise.time * 1.5, { opacity: 1 }, { opacity: 0 }, 0.2)
            $(this.parent + " .louversImage").addClass("show out");
            animateFromTo(this.parent + ' .main-3-info-links', thise.time * 1.2, { y: 0 }, { y: -35, ease: Power3.easeIn }, thise.time * 0.3)
            // images
            let delayImg = 0;
            let delayImgOpacity = 0;
            $(this.parent + " .b-img .wrap").each(function (index) {
                animateFromTo($(this), thise.time, {
                    y: 0,
                }, {
                    y: -150,
                    opacity: 0,
                    ease: Power1.easeIn
                }, delayImg += 0.025);
                // animateFromTo($(this).find('.wrap'), thise.time * 0.8, { opacity: 1 }, {
                //     opacity: 0,
                //     ease: Power1.easeIn
                // }, delayImgOpacity += 0.015 * index);
            })
            setTimeout(() => {
                // $(this.parent).fadeTo(0, 0);
                destroyScreen3ScrollEvents();
                done();
                this.clear();
            }, delayImg + 1500);
        }
    },
    screen4: {
        parent: '.sect-main-4',
        time: 0.7,
        clear: function () {
            $(this.parent).removeClass("flex lessPadding");
            TweenMax.set(this.parent + " .awards-box", { y: 0, opacity: 1 })
        },
        default: function (out = true) {
            const thise = this;
            animateFromTo(this.parent + ' h2', out ? this.time * 0.7 : this.time, {
                y: out ? 0 : 40,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -40 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power1.easeIn : Power1.easeOut
            })
        },
        in: function (done = () => { }) {
            $("#pagination").addClass("black");
            const thise = this;
            $(this.parent).find(">div").fadeTo(0, 1);
            this.default(false);
            let delay = 0;
            // 
            const main4 = $(this.parent + ' .main-4');
            if (window.innerWidth > 991 && $(this.parent + ' .wrap').innerHeight() < main4.innerHeight() - parseInt(main4.css('padding-top')) - parseInt(main4.css('padding-bottom'))) {
                $(this.parent).addClass("flex");
            } else {
                $(this.parent).addClass("lessPadding");
            }
            // 
            $(this.parent).find('.awards-item:not(.awards-item-empty)').each(function () {
                animateFromTo($(this), isFirefox ? 0.25 : thise.time * 1.9, { scale: 0 }, { scale: 1, ease: isFirefox ? Power1.easeOut : Elastic.easeOut.config(1, 1) }, (delay += 35 / 1000) + 0.4);
            })
            setTimeout(done, delay * 1000);

            if ($('.count1').length) {
                $('.count1').spincrement({
                    from: 0,
                    duration: 3000,
                    easing: 'linear',
                    leeway: 0,
                    //decimalPoint: '.'
                });
            }
        },
        out: function (done = () => { }, nextEl) {
            this.default(true);
            animateFromTo(this.parent + ' .awards-box', this.time * 0.9, { y: 1, opacity: 1 }, { y: -100, opacity: 0, ease: Power1.easeIn }, 0.3)
            const totalDelay = (this.time * 0.7 + 0.3) * 1000 + 300;
            setTimeout(() => {
                $(this.parent).find(">div").fadeTo(100, 0);
                this.clear();
                done();
            }, totalDelay);
        },
    },
    screen5: {
        parent: '.sect-main-5',
        time: 0.7,
        clear: function (out = true) {
            const thise = this;
            $(this.parent).find(".form-group-with-placeholder-textarea, .form-group, .mapSVG, .formSubmit, .hideImportant").removeClass('show hideImportant');
        },
        default: function (out = true) {
            const thise = this;
            animateFromTo(this.parent + ' .main-5-scroll-tc h2', out ? this.time * 0.7 : this.time, {
                y: out ? 0 : 40,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -40 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power1.easeIn : Power1.easeOut
            })
            $(this.parent + ' .footer-right').find(".footer-text > div, .footer-packages > div, .footer-masterclass > div, .mapSVG").each(function (index) {
                const time = thise.time * (out ? 0.7 : 1.1);
                animateFromTo($(this), time, {
                    y: out ? 0 : 35,
                    opacity: out ? 1 : 0,
                }, {
                    y: out ? -35 : 0,
                    opacity: out ? 0 : 1,
                    ease: out ? Power3.easeIn : Power2.easeOut,
                    onStart: () => {
                        setTimeout(() => {
                            if ($(this).hasClass("mapSVG")) {
                                $(this).addClass('show');
                            }
                        }, time / 3 * 1000);
                    }
                }, index * 50 / 1000 + 0.3);
            });
            let textareaDelay = 0;
            $(this.parent).find(".form-group-item").each(function (index) {
                $(this).find('.form-group').each(function (indexInColumn) {
                    if (!index) textareaDelay += (indexInColumn * 50 + 0.5);
                    setTimeout(() => {
                        $(this)[out ? 'removeClass' : 'addClass']('show');
                    }, (indexInColumn * 50 + 0.5) * (index ? (index * 5) : 1));
                });

                const arrList = $(this).find('.form-group .chosen-single, .form-group span.place-holder, .form-group .ic-date').map(function () {
                    if ($(this).hasClass('place-holder') && $(this).parent('label').find('.chosen-single').length) {
                        $(this).fadeTo(250, 0)
                        if ($(this).parent().hasClass('active')) {
                            $(this).stop(true).fadeTo(150, 0, () => {
                                $(this).addClass("hideImportant");
                            })
                        };
                        return '';
                    } else {
                        return $(this);
                    }
                })
                animateFromTo(arrList, thise.time * (out ? 0.7 : 1), {
                    y: out ? 0 : 15,
                    opacity: out ? 1 : 0,
                }, {
                    y: out ? -15 : 0,
                    opacity: out ? 0 : 1,
                    ease: out ? Power2.easeIn : Power2.easeOut
                }, index * 50 / 1000 + 0.5);
            })
            setTimeout(() => {
                $(this.parent).find(".form-group")[out ? 'removeClass' : 'addClass']('static');
                $(this.parent).find(".form-group .place-holder").removeAttr("style");
            }, out ? 500 : 1500);

            animateFromTo(this.parent + ' .form-group-with-placeholder-textarea textarea', out ? this.time * 0.7 : this.time, {
                y: out ? 0 : 25,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -25 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power3.easeIn : Power3.easeOut,
            }, textareaDelay / 1000 + thise.time * 1.3 * (out ? 0.5 : 1))
            setTimeout(() => {
                $(".form-group-with-placeholder-textarea")[out ? 'removeClass' : 'addClass']('show');
            }, textareaDelay + thise.time * 0.8 * 1000 - 200);

            animateFromTo(this.parent + ' .form-group-btn', out ? this.time * 0.7 : this.time, {
                opacity: out ? 1 : 0
            }, {
                opacity: out ? 0 : 1,
                onStart: () => {
                    setTimeout(() => {
                        $(this.parent + ' .formSubmit').addClass('show');
                    }, 0);
                },

            }, textareaDelay / 1000 + thise.time * 0.5 + (out ? 0.2 : this.time));
            animateFromTo(this.parent + ' .footer-line', this.time, {
                opacity: out ? 1 : 0, y: out ? "0%" : "100%"
            }, { opacity: out ? 0 : 1, y: out ? "100%" : "0%" }, out ? 1 : 0.5);
        },
        in: function (done = () => { }) {
            $("#pagination").removeClass("black");
            const thise = this;
            $('header').removeClass('dark-color');
            if ($('section.sect-main-1').hasClass('active')) {
                $('header').removeClass('small-contact');
            };
            // 
            $(this.parent).find(">div").fadeTo(500, 1);
            this.default(false);
            setTimeout(() => {
                $(this.parent + ' .hideImportant').addClass("showImportant").fadeTo(0, 0).fadeTo(250, 1, function () {
                    $(this).removeClass("showImportant hideImportant")
                })
                pagescroll.setInProgress(false);
            }, 2000);
            setTimeout(done, 1500);
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
            this.default(true);
            $(this.parent + ' .chosen-single').stop(true).fadeTo(0, 0)
            setTimeout(() => {
                $(this.parent).find(">div").fadeTo(500, 0, () => {
                    done();
                    this.clear();
                });
            }, 1000);

        }
    },
    screen6: {
        parent: '.screen6',
        time: 0.7,
        clear: function (out = true) {
            const thise = this;
            $(this.parent + ' .formSubmit').removeClass("show")
        },
        default: function (out = true) {
            const thise = this;
            animateFromTo(this.parent + ' .gp-2-box-out', this.time * (out ? 0.7 : 1.1), {
                y: out ? 0 : 35,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -35 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power1.easeIn : Power1.easeOut
            }, 0.5);
            animations.defaultFadeWave(this.parent, out, this.time, 0)
            // 
        },
        in: function (done = () => { }) {
            const thise = this;
            this.default(false)
            setTimeout(() => {
                $(this.parent + ' .formSubmit').addClass("show")
            }, 1000);
            $(this.parent).find(">div").fadeTo(1000, 1, done);
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
            this.default(true);
            setTimeout(() => {
                $(this.parent).find(">div").fadeTo(100, 0, () => {
                    done();
                    this.clear();
                });
            }, 1250);
        }
    },
    default: {
        parent: '.section.active',
        time: 0.7,
        clear: function (out = true) {
            const thise = this;
        },
        default: function (out = true) {
            const thise = this;
        },
        in: function (done = () => { }) {
            const thise = this;
            $(this.parent).fadeTo(500, 1, done);
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
            $(this.parent).fadeTo(0, 1, done);
        }
    },
    example: {
        parent: '.sect-main-3',
        time: 0.7,
        clear: function (out = true) {
            const thise = this;
        },
        default: function (out = true) {
            const thise = this;
        },
        in: function (done = () => { }) {
            const thise = this;
        },
        out: function (done = () => { }, nextEl) {
            const thise = this;
        }
    },

    defaultFadeWave: function (parent, out, time, fadeDelay = 0, factor = 100) {
        if (!out) {
            $(parent + " .wave").fadeTo(0, 0);
            setTimeout(() => {
                $(parent).find(".wave.title").fadeTo(0, 1);
            }, fadeDelay * 1000);
            setTimeout(() => {
                $(parent).find(".wave.description").fadeTo(0, 1);
            }, 0.3 * 1000);
            setTimeout(() => {
                $(parent).find(".wave.comingsoon").fadeTo(0, 1);
            }, 0.6 * 1000);
        };
        $(parent + " .wave.title .textLine").each(function (index) {
            animateFromTo($(this), time * (out ? 1 : 1.1), {
                y: out ? 0 : 40,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -40 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power2.easeIn : Power2.easeOut
            }, index * factor / 1000 + fadeDelay);
        });
        $(parent + " .wave.description .textLine").each(function (index) {
            animateFromTo($(this), time * (out ? 0.7 : 1.1), {
                y: out ? 0 : 35,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -35 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power2.easeIn : Power2.easeOut
            }, index * 50 / 1000 + 0.3);
        })

        $(parent + " .wave.comingsoon .textLine").each(function (index) {
            animateFromTo($(this), time * (out ? 1 : 1.1), {
                y: out ? 0 : 40,
                opacity: out ? 1 : 0,
            }, {
                y: out ? -40 : 0,
                opacity: out ? 0 : 1,
                ease: out ? Power2.easeIn : Power2.easeOut
            }, index * factor / 1000 + fadeDelay);
        });
    }
}


var screen6Wow, footerWow, wow;

const awardsLoop = {
    inited: false,
    tween: new TimelineMax(),
    init: function () {
        this.box = $(".awards-box");
        this.box.wrap('<div class="awardsWrap"></div>');
        this.box.after(this.box.clone().addClass('clone'));
        this.inited = true;
    },
    move: function (shift = false) {
        const item = document.querySelector(".awardsWrap .awards-box");
        this.tween.clear();
        this.tween.fromTo(
            ".awardsWrap .awards-box",
            20,
            { x: shift ? window.innerWidth / 3 : 0 },
            {
                x: -item.offsetWidth,
                ease: Power0.easeNone,
                onComplete: () => {
                    this.move();
                }
            }
        );
    },
}
function move_to_tablet() {
    $(".louversImage").removeClass("animated clear done show transition0s");
    $(".louversImage:not(.dafaultFade)").removeClass("wow");
    // $(".screen6 .sect-bottom").addClass("wow");
    $(".sect-bottom-scroll").addClass("show");
    $(".main-5-scroll-tc h2").addClass("dafaultFade wow");
    $(".main-2-info, .main-3-info, .louversImage, .main-4, .formSubmit, .sect-main-5, .form-group").addClass("wow");
    $(".footer-right").find(".footer-text > div, .footer-packages > div, .footer-masterclass > div, .mapSVG").each(function (index) {
        $(this).addClass("wow dafaultFade").attr('delay', index * 50 + 100);
    })
    $(".footer-soc li").each(function (index) {
        $(this).addClass("wow dafaultFade").attr('delay', index * 50 + 100);
    })
    // 
    if (typeof moveParallax != 'undefined') moveParallax.resetMouse();
    $('.section, [class^="sect-main-"] > div, .footer-line, div').removeAttr("style");
    createWow();
    if (mainPage && videoPlayer.inited) videoPlayer.play();
    if ($(".gyp-1").length) animations.galleryScreen1.in()

    if ($(".infiniteScroll").length) {
        if (myInfiniteScroll) {
            $(".infiniteScroll .b-imgs").fadeTo(500, 1);
            $(".louversImage").addClass("show").css("visibility", "visible");
        }
        // setTimeout(() => { $(".louversImage").addClass("clear"); }, 1200);
        // setTimeout(() => { $(".louversImage").addClass("done"); }, 2500);
        if (!mainPage) {
            $("body, html").scrollTop(0).css({
                "overflow": 'hidden',
                position: "fixed"
            }).height(vhCheck().windowHeight).addClass("hasInfiniteScroll")
        }
        if (myInfiniteScroll) myInfiniteScroll.setPosRow();
    }
    if ($("#packages").length) {
        showHidePackagesSidebar(true)
    }

    // packages videos
    if ($("#servicesVideos").length) {
        if (servicesVideos.inited) servicesVideos.stopAllVideos(true);
    };

    if (initDesktop) {
        animations.screen1.in()
        animations.screen2.in()
    }
    if ($(".sect-main-1").length) {
        animations.screen1.in()
        $(".main-1 video, .main-1 #video, .main-1 .posterMobile").fadeTo(0, 1)
    }
    $(".b-img").removeAttr("style")
}

function move_to_desktop() {
    $("#preloader").hide();
    $(".louversImage").removeClass("animated clear done show transition0s");
    $(".louversImage:not(.dafaultFade)").removeClass("wow");
    $(".louversImage.dafaultFade").addClass("show");
    if (typeof moveParallax != 'undefined') moveParallax.disable();
    if (typeof moveParallax != 'undefined') moveParallax.resetMouse();
    $('.pagescroll').removeAttr("style");
    if (pagescroll.init) {
        pagescroll.initSwipe();
        $(".louversImage").removeClass("animated clear done show");
        destroyWow();
        pagescroll.el.moveTo(1);

        setTimeout(() => {
            startPagescroll();
            if (videoPlayer.inited) videoPlayer.play()
        }, 2000);
    }
    $('.pagescroll > section').each(function (index) {
        $(this).css({
            position: 'absolute',
            top: index * 100 + "%"
        })
    });

    // packages videos
    if ($("#servicesVideos").length) {
        if (!servicesVideos.inited) servicesVideos.init();
        servicesVideos.animations.firstIn.call(servicesVideos)
    }

    $(".section").css({ "opacity": "" })


    if (hold_scroll_pageStatus && myInfiniteScroll && myInfiniteScroll.fixedPage) {
        myInfiniteScroll.infiniteScroll.removeClass("fixed")
        myInfiniteScroll.infiniteScroll.find(".scrollDown").fadeOut(250);
        myInfiniteScroll.holdPageOnScrollResize();
        hold_scroll_page(false, true);
        myInfiniteScroll.parallax.resetMouse(true, true);
        myInfiniteScroll.parallax.disable();
    }

}

function destroyWow() {
    $('.wow, .footerWow, .screen6').removeClass('wow').removeAttr("style");
    new WOW({ mobile: false }).init();
}
function createWow() {
    footerWow = new WOW(
        {
            boxClass: 'footerWow',      // класс, скрывающий элемент до момента отображения на экране (по умолчанию, wow)
            animateClass: 'animated', // класс для анимации элемента (по умолчанию, animated)
            offset: 150,          // расстояние в пикселях от нижнего края браузера до верхней границы элемента, необходимое для начала анимации (по умолчанию, 0)
            mobile: true,       // включение/отключение WOW.js на мобильных устройствах (по умолчанию, включено)
            live: true,       // поддержка асинхронно загруженных элементов (по умолчанию, включена)
            callback: function (box) {
                // функция срабатывает каждый раз при старте анимации
                // аргумент box — элемент, для которого была запущена анимация
                if (window.innerWidth > 991) {
                    animations.screen5.in();
                };
            },
            scrollContainer: null // селектор прокручивающегося контейнера (опционально, по умолчанию, window)
        }
    ).init();
    wow = new WOW(
        {
            boxClass: 'wow',      // класс, скрывающий элемент до момента отображения на экране (по умолчанию, wow)
            animateClass: 'animated', // класс для анимации элемента (по умолчанию, animated)
            offset: 50,          // расстояние в пикселях от нижнего края браузера до верхней границы элемента, необходимое для начала анимации (по умолчанию, 0)
            mobile: true,       // включение/отключение WOW.js на мобильных устройствах (по умолчанию, включено)
            live: true,       // поддержка асинхронно загруженных элементов (по умолчанию, включена)
            callback: function (box) {
                // функция срабатывает каждый раз при старте анимации
                // аргумент box — элемент, для которого была запущена анимация
                if ($(box).hasClass("main-2-info")) {
                    animations.screen2.in();
                };
                // if ($(box).closest(".screen6").length && !$(box).hasClass("formSubmit")) {
                //     console.log($(box))
                //     animations.screen6.in();
                // };
                if ($(box).hasClass("main-3-info:not(.infiniteScroll)")) {
                    animations.screen3.in();
                };
                if ($(box).hasClass("main-4")) {
                    animations.screen4.in();
                    if (awardsLoop.inited) awardsLoop.move(true)
                    $(box).fadeTo(0, 1);
                };
                if ($(box).hasClass('btn-play')) {
                    $(box).addClass('show');
                };
                if ($(box).hasClass('mapSVG')) {
                    setTimeout(() => {
                        $(box).addClass('show');
                    }, 750);
                };
                if ($(box).hasClass('dafaultFade')) {
                    $(box).fadeTo(0, 1);
                    const bigShift = $(box).hasClass('bigShift');
                    const photoItem = $(box).hasClass('photo-item');
                    let delay = 0;
                    if ($(box).attr('delay') != undefined) delay = parseInt($(box).attr('delay')) / 1000;
                    if ($(box).attr('data-delay') != undefined) delay = parseInt($(box).attr('data-delay')) / 1000;
                    animateFromTo(box, 0.7, {
                        y: (bigShift ? 60 : 15) * (photoItem ? 0 : 1),
                        opacity: 0,
                    }, {
                        y: 0,
                        opacity: 1,
                        ease: Power2.aseIn
                    }, delay);
                };
                if ($(box).hasClass("form-group")) {
                    $(box).fadeTo(0, 1).addClass('show');
                    let elems = $(box).find('.place-holder').add($(box).find('.ic-date'))
                    if ($(box).find('.chosen-single').length && $(box).find('.place-holder').length) {
                        elems = $(box).find('.chosen-single');
                    };
                    if ($(box).find('textarea').length) {
                        elems = $(box).find('textarea');
                    };
                    animateFromTo(elems, 0.7, {
                        y: 15,
                        opacity: 0,
                    }, {
                        y: 0,
                        opacity: 1,
                        onComplete: () => {
                            $(box).removeClass('wow').addClass("static").find(".chosen-single, .place-holder").removeAttr("style");
                        },
                        ease: Power2.easeIn
                    }, 0.1);
                };
                if ($(box).hasClass("louversImage")) {
                    setTimeout(() => {
                        $(box).addClass("show");
                        setTimeout(() => { $(box).addClass("clear"); }, 1200);
                        setTimeout(() => { $(box).addClass("done"); }, 2500);
                    }, 50);
                };
                if ($(box).hasClass("formSubmit")) {
                    setTimeout(() => {
                        $(box).addClass("show");
                    }, 50);
                };
            },
            scrollContainer: null // селектор прокручивающегося контейнера (опционально, по умолчанию, window)
        }
    ).init();
    screen6Wow = new WOW(
        {
            boxClass: 'screen6',      // класс, скрывающий элемент до момента отображения на экране (по умолчанию, wow)
            animateClass: 'animated', // класс для анимации элемента (по умолчанию, animated)
            offset: 150,          // расстояние в пикселях от нижнего края браузера до верхней границы элемента, необходимое для начала анимации (по умолчанию, 0)
            mobile: true,       // включение/отключение WOW.js на мобильных устройствах (по умолчанию, включено)
            live: true,       // поддержка асинхронно загруженных элементов (по умолчанию, включена)
            callback: function (box) {
                // функция срабатывает каждый раз при старте анимации
                // аргумент box — элемент, для которого была запущена анимация
                animations.screen6.in();
            },
            scrollContainer: null // селектор прокручивающегося контейнера (опционально, по умолчанию, window)
        }
    ).init();
}

if (isIpad && window.innerWidth > 991) {
    window.viewportUnitsBuggyfill.init({
        force: true,
        refreshDebounceWait: 250,
        hacks: window.viewportUnitsBuggyfillHacks
    });
}
// 18.09

let myInfiniteScroll;
$(document).ready(function () {
    if ($(".sect-main-3").length) {
        $(".sect-main-3 .b-imgs").append("<div class='absoluteGrid'></div>");
        if ($(".infiniteScroll").length) {
            myInfiniteScroll = new InfiniteScroll(true);
        }
    };
});




let customFadeBlock = {
    listArr: [
        '.sect-main-3 .defaultGrid .b-img',
    ],
    divs: [],
    shift: 50,
    fadeShift: 70,
    fadeShiftDelay: 50,
    fadeTime: 1.5,
    init: function (list = this.listArr.join(',')) {
        this.inited = true;
        const thise = this;
        this.list = list;
        this.windowHeight = window.innerHeight;
        this.update();
        $(this.list).map(function () {
            return $(this);
        }).each(function () {
            const offset = $(this).offset().top;
            thise.divs.push({
                el: $(this),
                top: offset,
                fade: false,
            });
        });
        this.check();
    },
    check: function () {
        if (!this.divs.length) return false;
        const indexesToRemove = [];
        this.divs.forEach((elem, index, arr) => {
            const { el, top, fade } = elem;
            if (!fade) {
                const scrollTop = $('.pagescroll section.active').scrollTop();
                elem.top = el.offset().top + scrollTop;
                if (elem.top < scrollTop + window.innerHeight - this.fadeShiftDelay) {
                    elem.fade = true;
                    indexesToRemove.push(index);
                    this.fadeElem(el, 0);
                }
            }
        });
        indexesToRemove.forEach((value, index) => {
            this.divs.splice(value - index, 1);
        });
        this.update();
    },
    update: function () {
        this.divs.forEach(({ el, top, fade }) => {
            if (!fade) top = el.offset().top + $('.pagescroll section.active').scrollTop();
        });
    },
    fadeElem: function (el, delay = 0) {
        $(el).addClass("show");
        setTimeout(() => { $(el).addClass("clear"); }, 1200);
        setTimeout(() => { $(el).addClass("done"); }, 2500);
    }
}


const initScreen3ScrollEvents = () => {
    if (window.innerWidth > 991) {
        customFadeBlock.init();
        $(".sect-main-3").on('scroll.customFadeBlock', () => { customFadeBlock.check() })
    }
}

const destroyScreen3ScrollEvents = () => {
    $(".sect-main-3 .b-img.dafaultFade").removeClass("clear done show")
    $(".sect-main-3").off('scroll.customFadeBlock')
};
let scrolDownAccess = true;

$(document).ready(function () {
    setTimeout(() => {
        $('.lazyCustom:not(.load):not(.infiniteImage)').each(function () {
            if ($(this).attr('data-src') != '') {
                $(this).attr('src', $(this).attr('data-src')).addClass("load");
            }
        });
    }, 500);
    $('.lazy').lazy({
        delay: 100
    });
    $("ul.main-menu li.has-sub-menu").hover(function () {
        $(this).closest('header').find(".header-shop-btn").fadeTo(250, 0)
    }, function () {
        $(this).closest('header').find(".header-shop-btn").fadeTo(250, 1)
    });
    $("#pagination .dot").click(function () {
        if ($(this).hasClass("active")) return false;
        animations.goTo($(this).index() + 1)
        $(this).parent().addClass("disabled")
    });

    $(".sect-bottom-scroll > span").click(function () {
        if (!scrolDownAccess) return false;
        scrolDownAccess = false;

        if ($("body").hasClass("p-gallery-photo")) {
            scrollTo('body, html', window.innerHeight - 58, 1)
            scrolDownAccess = true;
        } else {
            animations.goTo($(".section.active").data('index') + 1)
        }
    })
    // 

    if ($(".infiniteScroll").length && window.innerWidth < 600) {
        if (window.innerHeight <= 400) {
            //   $("#rotatePhone")[window.innerWidth > window.innerHeight ? "addClass" : "removeClass"]('show');
            $("#rotatePhone")[(window.innerWidth > window.innerHeight && window.innerHeight > 400 || window.innerHeight < 400 && window.innerWidth > window.innerHeight) ? "addClass" : "removeClass"]('show');
        }
    }
    // $(window).resize(checkRotatePhone);
    window.addEventListener("orientationchange", checkRotatePhone);
});

const checkRotatePhone = () => {
    if (window.innerWidth > 990) {
        $("#rotatePhone").removeClass('show');
        return false;
    }
    $("#rotatePhone")[(window.innerWidth < window.innerHeight && window.innerHeight > 400 || window.innerHeight < 400 && window.innerWidth > window.innerHeight) ? "addClass" : "removeClass"]('show');
}