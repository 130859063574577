const localhost = window.location.host === 'cinestory' || window.location.host === 'cinestory-animation.redlab.site';

const prefix = (index) => index < 10 ? "0" + index : index;
let clearStepPackagesRow;
const scrollTo = (block, scrollTop, time = 1) => {
    TweenMax.to(block, time, {
        scrollTop: scrollTop,
        ease: "Power2.easeInOut",
    });
};
// 
let signContractSuccess = false;
let headerMenuBtnAccess = true;
let ease = Power2.easeInOut;
let changePackagesListImageAccess = true;
let changePackagesStepAccess = true;
let changePackagesQuie = [];
let scrollAddonsWrap;
const order = {
    planningNow: false,
    addons: {}
};

const changePackagesListImage = (index = 0) => {
    if (!changePackagesListImageAccess) {
        changePackagesQuie.push(index);
        return false;
    };
    changePackagesListImageAccess = false;
    const items = $(".servicesImagesList .servicesImagesListItem");
    const shift = 50;
    const active_item = items.filter(".active")
    const next_item = items.filter(`[data-index=${index}]`).length ? items.filter(`[data-index=${index}]`) : items.eq(index);
    next_item.css({ 'position': 'absolute', opacity: 0 }).addClass("active animated")
    animateFromTo(next_item, 0.3, { x: shift * (active_item.index() > index ? -1 : 1), opacity: 0 },
        {
            x: 0, opacity: 1, onComplete: () => {
                items.removeClass("active animated");
                next_item.addClass("active")
                items.css({
                    "transform": '',
                    position: 'static'
                });
                changePackagesListImageAccess = true;
                if (changePackagesQuie.length) {
                    changePackagesListImage(changePackagesQuie[changePackagesQuie.length - 1])
                    changePackagesQuie = [];
                };
            }
        });
};

const showHideCalendar = (show = true) => {
    $('#calendar')[show ? 'slideDown' : 'slideUp'](250)
    calendar.show = show;
    document[show ? 'addEventListener' : 'removeEventListener']('click', calendar.click, false);
    hold_scroll_page(show);

    $(".packagesSidebar .bg")[show ? 'fadeIn' : 'fadeOut'](250)
}

const showHidePackagesSidebar = (show = true) => {
    animateFromTo('#packagesSidebar', 0.25, { display: 'block' }, { y: show ? '0%' : '100%' })
    animateFromTo('.packagesBlock', 0.25, {}, { marginBottom: show ? $('#packagesSidebar').innerHeight() : 0 })
}

const validetePackagesSidebar = () => {
    const nextStepAccess = true;
    $("#packagesSidebar #nextStep")[nextStepAccess ? 'removeClass' : 'addClass']('disabled')
}

let addonsCategory;
const getAddonsSizeContainer = () => {
    let totalWidth = 0;
    if (typeof addonsCategory == 'undefined') addonsCategory = $(".addonsCategory");
    const wraps = addonsCategory.find(">.wrap")
    wraps.removeAttr("style");
    wraps.each(function () {
        const addons = $(this).find('.addonsItem');
        const margin = parseInt(addons.css("margin-right"));
        const width = addons.innerWidth();
        const count = Math.ceil(addons.length / 2);
        $(this).width(width * count + margin * 1.5 * (count - 1) + 30);
        totalWidth += $(this).parent().innerWidth();
    });
    addonsCategory.parent().width(totalWidth);
    getAddonsParentSizeContainer(addonsCategory);
    detectBottomAddons();
    addonsAncorPos.forEach(element => {
        const ancor = $(`.addonsCategory[data-name='${element.name}']`);
        element.left = ancor.position().left;
    });
};

const detectBottomAddons = () => {
    if (typeof addonsCategory == 'undefined') addonsCategory = $(".addonsCategory");
    addonsCategory.each(function () {
        const height = $(this).innerHeight();
        const top = $(this).offset().top;
        $(this).find(".addonsItem").each(function () {
            $(this)[$(this).offset().top > top + height / 3 ? 'addClass' : 'removeClass']('bottom')
        })
    })
};

const getAddonsParentSizeContainer = (items) => {
    let totalWidth = 0;
    if (typeof addonsCategory == 'undefined') addonsCategory = $(".addonsCategory");
    const margin = parseInt(items.css("margin-right"));
    items.parent().width('');
    items.removeAttr("style");
    items.each(function () {
        totalWidth += $(this).innerWidth() + margin;
    });
    totalWidth -= margin;
    items.parent().width(totalWidth);
};

const scrollAddons = (shift = 100, func = () => { }) => {
    if (scrollAddonsWrap == undefined) scrollAddonsWrap = document.getElementById('scrollAddonsWrap');
    TweenMax.to(scrollAddonsWrap, 0.2, {
        scrollLeft: scrollAddonsWrap.scrollLeft + shift,
        ease: "Power1.easeOut",
        onComplete: () => {
            detectCurrentAddonAncor();
            func();
        },
    });
};

const scrollAddonsEvent = (e) => {
    const shift = 250;
    e = e || window.event;
    if (e.target.closest('.stepAddons') == null) return false;
    var delta = e.deltaY || e.detail || e.wheelDelta;
    if (delta <= 0 && pageYOffset <= 0) { // to top
        scrollAddons(-shift, scrollAddonsCallBack)
    }
    if (delta > 0 && pageYOffset + window.innerHeight == document.querySelector('body').scrollHeight) { // to bottom
        scrollAddons(shift, scrollAddonsCallBack)
    };
};
let addonsHover = {
    tween: new TimelineLite(),
    hover: false,
    ease: false,
};
const scrollHoverAddons = (childWidth) => {
    if (scrollAddonsWrap == undefined) scrollAddonsWrap = document.getElementById('scrollAddonsWrap');
    if (childWidth == undefined) childWidth = scrollAddonsWrap.querySelector(".scrollAddons").clientWidth;
    if (scrollAddonsWrap.scrollLeft <= 0 && !addonsHover.right || addonsHover.right && scrollAddonsWrap.scrollLeft + scrollAddonsWrap.clientWidth >= childWidth) return false
    const shift = addonsHover.right ? 150 : -150;
    if (addonsHover.hover) {
        addonsHover.tween.kill();
        addonsHover.tween = new TimelineLite();
    }
    const ease = addonsHover.hover ? (addonsHover.ease ? "Power1.easeIn" : "Power0.easeNone") : "Power1.easeOut";
    // clear
    scrollAddonsWrap.parentNode.classList.remove("hideLeft")
    scrollAddonsWrap.parentNode.classList.remove("hideRight")
    // 
    addonsHover.tween.to(scrollAddonsWrap, addonsHover.ease ? 0.5 : 0.3, {
        scrollLeft: scrollAddonsWrap.scrollLeft + shift,
        ease: ease,
        onComplete: () => {
            scrollAddonsCallBack()
            if (addonsHover.hover) {
                addonsHover.ease = false;
                scrollHoverAddons(childWidth);
            }
        }
    });
}
const scrollHoverAddonsEvent = (e) => {
    const right = e.target.classList.contains("right");
    const hover = e.type === 'mouseenter';
    addonsHover.e = e;
    addonsHover.right = right;
    addonsHover.hover = hover;
    addonsHover.ease = true;
    scrollHoverAddons()
};

const scrollAddonsCallBack = () => {
    if (scrollAddonsWrap == undefined) scrollAddonsWrap = document.getElementById('scrollAddonsWrap');
    const childWidth = scrollAddonsWrap.querySelector(".scrollAddons").clientWidth;
    // clear
    scrollAddonsWrap.parentNode.classList.remove("hideLeft")
    scrollAddonsWrap.parentNode.classList.remove("hideRight")
    // 
    if (scrollAddonsWrap.scrollLeft <= 0) {
        scrollAddonsWrap.parentNode.classList.add("hideLeft")
    }
    if (scrollAddonsWrap.scrollLeft + scrollAddonsWrap.clientWidth >= childWidth) {
        scrollAddonsWrap.parentNode.classList.add("hideRight")
    };
};

const scrollClickAddonsEvent = (e) => {
    const shift = 400;
    const right = e.target.classList.contains("right");
    scrollAddons(right ? shift : -shift, scrollAddonsCallBack);
}

const scrollAddonsTrigger = (init = true) => {
    if (init) {
        window.addEventListener('wheel', scrollAddonsEvent, { passive: false });
        window.addEventListener('DOMMouseScroll', scrollAddonsEvent, { passive: false });
        document.addEventListener('touchmove', scrollAddonsEvent, { passive: false });
        document.querySelectorAll('.stepAddons .scrollElem').forEach(el => {
            el.addEventListener('click', scrollClickAddonsEvent, { passive: false })
        });
        // document.querySelectorAll('.stepAddons .scrollElem').forEach(el => {
        //     el.addEventListener('mouseenter', scrollHoverAddonsEvent, { passive: false })
        // });
        // document.querySelectorAll('.stepAddons .scrollElem').forEach(el => {
        //     el.addEventListener('mouseleave', scrollHoverAddonsEvent, { passive: false })
        // });
    } else {
        window.removeEventListener('wheel', scrollAddonsEvent, { passive: false });
        window.removeEventListener('DOMMouseScroll', scrollAddonsEvent, { passive: false });
        document.removeEventListener('touchmove', scrollAddonsEvent, { passive: false });
        document.querySelectorAll('.stepAddons .scrollElem').forEach(el => {
            el.removeEventListener('click', scrollClickAddonsEvent, { passive: false })
        });
        // document.querySelectorAll('.stepAddons .scrollElem').forEach(el => {
        //     el.removeEventListener('mouseenter', scrollHoverAddonsEvent, { passive: false })
        // });
        // document.querySelectorAll('.stepAddons .scrollElem').forEach(el => {
        //     el.removeEventListener('mouseleave', scrollHoverAddonsEvent, { passive: false })
        // });
    }
}

var arr_timers_form_input = [];
var arr_timers_form_textarea = [];
var submitError = false;
function createError(item) {
    create_timer(item);
    item.closest('label').addClass("has_error");
    item.closest('label').find(".error_text").fadeIn(250);
};
function create_timer(item) {
    let type = item[0].tagName;
    let index = $("form input").index(item);
    let mass = (type == 'INPUT') ? arr_timers_form_input[index] : arr_timers_form_textarea[index];
    clearTimeout(mass);
    arr_timers_form_input[index] = setTimeout(function () {
        item.parent().removeClass("has_error");
        item.parent().find(".error_text").fadeOut(300);
    }, 5000);
};

function scrollToErrorInput() {
    let inputTop, scrollTop;
    inputTop = $(this).closest("label").offset().top;
    scrollTop = $(window).scrollTop();
    if (inputTop < scrollTop + $("header").innerHeight() || inputTop > scrollTop + window.innerHeight) {
        scrollTo('body, html', inputTop - $("header").innerHeight() - 50)
    }
};

function errorDo(text) {
    $(this).nextAll(".error_text").remove();
    $(this).after('<span class="error_text">' + text + '</span>');
    // if (!submitError) scrollToErrorInput.call($(this));
    submitError = true;
    createError($(this));
}


const changePackagesStep = (index = 0, li, callBack = () => { }) => {
    if (!changePackagesStepAccess) return false;
    clearTimeout(headerMenuBtnTimer);
    if ($(".header-menu:visible").length) {
        $("#headerMenuBtn").click()
    };
    changePackagesStepAccess = false;
    const shift = 50;
    const items = $(".packagesStep");
    const active_item = items.filter(".active")
    const next_item = items.eq(index);
    showHideCalendar(false);
    showHidePackagesSidebar(true);
    animateFromTo(active_item, 0.3, { x: 0, opacity: 1 },
        {
            x: shift * (active_item.index() > index ? 1 : -1),
            display: 'none',
            opacity: 0, onComplete: () => {
                scrollTo('body, html', 0, 0)
                next_item.css("display", "flex");
                items.removeClass("active");
                next_item.addClass("active")
                changePackagesStepCallBack(active_item, next_item, index, callBack);
                animateFromTo(next_item, 0.3, { x: shift * (active_item.index() > index ? -1 : 1), opacity: 0 },
                    {
                        x: 0, opacity: 1,
                        onComplete: () => {
                            li.removeClass("active current");
                            li.eq(index).addClass("active current");
                            items.css({
                                "transform": '',
                                position: 'static'
                            });
                            changePackagesStepAccess = true;
                        }
                    });
            }
        }
    )
};

const changePackagesStepCallBack = (active_item, next_item, index, callBack) => {
    let nextStepText = 'next';
    // if (window.innerWidth <= 991) nextStepText = 'payment';
    // start stepAddons
    if (active_item.hasClass("stepAddons")) {
        $(window).off('resize.packages', getAddonsSizeContainer);
        if (!mobile) {
            scrollAddonsTrigger(false);
        }
    }
    // if (active_item.hasClass("stepServices")) {
    // setPaymentData();
    // }
    // console.log('next_item', next_item)
    // if (next_item.hasClass("stepPackages")) {
    //     createStepPackages();
    // }
    if (!active_item.hasClass("stepPackages")) {
        runSpincrement();
        if (order.showAddPlanning) createStepPackages();
        // order.showAddPlanning = false;
        setPaymentData();
        packageVideos.animations.firstIn.call(packageVideos)
    }
    // if (next_item.hasClass("stepPackages")) {
    //     createStepPackages();
    // }
    if (servicesVideos.inited) {
        servicesVideos.stopAllVideos(true);
    };
    if (next_item.hasClass("stepServices")) {
        if (window.innerWidth > 991) {
            const { photo, video, planning } = getCheckedList();
            servicesVideos.animations[planning ? 'teamSelected' : 'firstInTeam'].call(servicesVideos, 0);
            servicesVideos.animations[photo ? 'teamSelected' : 'firstInTeam'].call(servicesVideos, 1);
            servicesVideos.animations[video ? 'teamSelected' : 'firstInTeam'].call(servicesVideos, 2);
        }
    };

    if (active_item.hasClass("stepAddons")) {
        $("#selectedAddons").fadeTo(250, 0);
        $(".addonsCategory").removeClass("active").first().addClass("active")
    }
    if (next_item.hasClass("stepAddons")) {
        nextStepText = 'next Add-ons';
        if (scrollAddonsWrap == undefined) scrollAddonsWrap = document.getElementById('scrollAddonsWrap');
        // clear
        scrollAddonsWrap.parentNode.classList.add("hideLeft")
        scrollAddonsWrap.parentNode.classList.remove("hideRight")
        // 
        TweenMax.set('.scrollAddonsWrap', { scrollLeft: 0 });
        $("#addonsAncors .packagesListItem").removeClass('active').eq(0).addClass("active")
        getAddonsSizeContainer();
        $(window).on('resize.packages', getAddonsSizeContainer);
        if (!mobile) {
            scrollAddonsTrigger(true);
        }
        $("#selectedAddons").delay(250).fadeTo(250, 1);

    }
    // end stepAddons

    $(".stepPackagesRow").hide().filter('.first').show();
    // order.showAddPlanning = false;

    if (next_item.hasClass("stepPayment")) {
        nextStepText = 'payment';
        if (!signContractSuccess) $("#nextStep").addClass("disabled");
    } else {
        $("#nextStep").removeClass("disabled");
    }

    callBack();

    $("#nextStep").text(nextStepText)
}

const getCheckedList = () => {
    const checked = $('.packagesList').find("input[name='packagesList']");
    const checkedList = (() => {
        let arr = [];
        checked.filter(":checked").each(function () {
            arr.push($(this).val());
        })
        return arr;
    })();
    const photo = checkedList.includes('photo');
    const video = checkedList.includes('video');
    const planning = checkedList.includes('planning');
    return { photo, video, planning }
};
// 
const createStepPackages = () => {
    order.showAddPlanning = false;
    const { photo, video, planning } = getCheckedList();
    let key = 'photo';
    if (photo && !video) key = 'photo';
    if (!photo && video) key = 'video';
    if (photo && video) key = 'photo_video';
    if (!photo && !video && planning) key = 'planning';
    const addPlanning = (photo || video) && planning ? true : false;
    order.package = key;
    order.addPlanning = addPlanning;
    order.packagesList = { photo, video, planning };
    order.services_list = packagesData[key].services_list;
    // $(".stepPackagesRow .text, .packageDetails").stop(true).fadeTo(250, 0)
    createPackagesCardList();
    createPackageDetails();
    createCharacters(false);
    showCurrentCharacters(false, 0);
    if (addPlanning) {
        createCharacters(true);
        showCurrentCharacters(true, 0);
    }
    createAddonsCategory();
};
let uniqCharacters = {};

const showCurrentCharacters = (planning = false, index) => {
    if (index == undefined) index = 0;
    const { characters } = planning ? packagesData.planning.services_list[index] : order.services_list[index];
    let names = characters.map(el => el.name) || [];
    // console.log($(".stepPackagesRow." + (planning ? 'second' : 'first') + " .personalBlockColumn"))
    $(".stepPackagesRow." + (planning ? 'second' : 'first') + " .personalBlockColumn").each(function () {
        const index = $(this).index();
        const name = $(this).data("name");
        const isActive = $(this).hasClass("active");
        $(this)[names.includes(name) ? 'addClass' : 'removeClass']('active');
        if (!names.includes(name)) {
            if (isActive) packageVideos.animations.out.bind(packageVideos)(index)
        } else {
            if (!isActive) packageVideos.animations.in.bind(packageVideos)(index)
        }
    });
}

const createCharacters = (planning = false) => {
    const personalBlock = $(".stepPackagesRow." + (planning ? 'second' : 'first') + " .personalBlock");
    const services_list = planning ? packagesData.planning.services_list : order.services_list;
    uniqCharacters = (() => {
        let obj = {};
        let list = [...new Set(services_list.map(el => {
            return el.characters.forEach(element => {
                obj[element.name] = element;
            });
        }))];
        return obj;
    })();
    let html = '';
    const sordedCharacters = Object.keys(uniqCharacters).sort().reduce((r, k) => (r[k] = uniqCharacters[k], r), {});
    let characterIndex = 0;
    console.log('sordedCharacters', sordedCharacters)
    for (const key in sordedCharacters) {
        const user = sordedCharacters[key];
        html += `<div class="personalBlockColumn" data-name="${user.name}">`
        if (window.innerWidth > 991) {
            user.video.forEach((video, index) => {
                html += `<video class="${!index ? 'active' : ''}" src="${video}" data-character_index="${characterIndex}" data-index="${index}" muted="muted" preload="auto" playsinline ></video >`;
            });
            html += '<div class="bg"></div>'
        } else {
            html += `<img src="${user.image}"></div>`
        }
        html += `</div>`;
        characterIndex++;
    };
    packageVideos.removeEvents();
    personalBlock.html(html);
    packageVideos.updateChildren();
}

const createPackagesCardList = (planningNow = false, once = false) => {
    order.packagesCardPlanning = 0;
    order.packagesCard = 0;
    const data = (!planningNow && !once) ? order.services_list : packagesData.planning.services_list;
    const html = data.reduce((sum, current, index) => {
        const { name, price } = current;
        return sum + `<li class="packagesCard">
            <label class="radio">
                <input type="radio" value="${name}" data-index="${index}" name="${planningNow ? 'packagesCardPlanning' : 'packagesCard'}" data-price-months="${price.months}" data-price-total="${price.total}" ${!index ? ' checked ' : ''} >
                <p>
                    <span class="circle"></span>
                    <span>${name}</span></p>
                    <em><b>$<span class="perMonth">${price.months}</span>/mo*</b> or $<span
                        class="total">${price.total}</span></em>
            </label>
        </li>`
    }, '');
    const { photo, video, planning } = order.packagesList;
    $(".packagesCardList").eq(planningNow ? 1 : 0).html(html);
    // $(".stepPackagesRow.second")[(photo || video) && planning ? 'show' : 'hide']();
    TweenMax.set('.stepPackagesRow.second', {
        display: (photo || video) && planning ? 'flex' : 'none'
    });
    if ((photo || video) && !planning) {
        $(".stepPackagesRow.second").html(clearStepPackagesRow)
    };
    if ((photo || video) && planning && !once) createPackagesCardList(true, true)
};

const createPackageDetails = (planningNow = false, once = false, index) => {
    if (index == undefined) {
        index = parseInt($(".packagesCardList").eq(planningNow ? 1 : 0).find('input:checked').attr('data-index'));
    }
    const current = (!planningNow) ? order.services_list[index] : packagesData.planning.services_list[index];
    const { title, description, noticeList, details_list } = current.content;
    const packageTitle = (() => {
        if (planningNow) return packagesData.planning.name + ' ' + packagesData.planning.services_list[index].name
        return packagesData[order.package].name + ' ' + packagesData[order.package].services_list[index].name;
    })()
    $(".stepPackagesRow").eq(planningNow ? 1 : 0).find(".leftContent .text").html(`<h2>${packageTitle}</h2><p>${title}</p>`);
    $(".stepPackagesRow").eq(planningNow ? 1 : 0).find('.rightContent .title').text(planningNow ? 'Planning' : packagesData[order.package].name);
    // notice
    let noticeListHTML = '';
    if (noticeList && noticeList.length) {
        noticeListHTML = noticeList.reduce((sum, current, index) => {
            const { name, text } = current;
            return sum + `<div class="notice">
                    <h5>${name}</h5>
                    <span>${text}</span>
                </div>
                <div class="circle"></div>`
        }, '');
    };
    $(".stepPackagesRow").eq(planningNow ? 1 : 0).find(".packageDetails .noticeList").html(noticeListHTML);
    // detailsList
    let detailsListHTML = '';
    if (details_list && details_list.length) {
        detailsListHTML = details_list.reduce((sum, current, index) => {
            const { count, name } = current;

            return sum + `<div class="detailsListItem">
                    <h5>${splitDetailsListItemTitle(count)}</h5>                
                    <span>${name}</span>
                </div>
            <span class="line"></span>`}, '');
    };
    $(".stepPackagesRow").eq(planningNow ? 1 : 0).find(".packageDetails .detailsList").html(detailsListHTML);
    const { photo, video, planning } = order.packagesList;
    if ((photo || video) && planning && !once) {
        createPackageDetails(true, true)
    } else {
        setPaymentData();
    }
};

const splitDetailsListItemTitle = (count) => {
    if (count.indexOf('-') >= 0) return count;
    const number = prefix(count.replace(/\D+/gi, ''));
    const character = count.replace(/\d+/gi, '')
    const numberHtml = `<span class="countWrap">
        <span class="number main">${number}</span>
        <span class="number hidden">${number}</span>
    </span>`;
    const characterHtml = `<span class="character">${character}</span>`;
    return `${numberHtml}${character ? characterHtml : ''}`;
};

const runSpincrement = () => {
    $('.detailsListItem .main.number').spincrement({
        thousandSeparator: "",
        duration: 650,
        // leeway: 0
    });
}

const validatePackages = (move = true) => {
    const currentStep = $(".packagesStep.active");
    let error = false;
    // if (window.innerWidth > 991) {
    // stepServices
    if (currentStep.hasClass("stepServices")) {
        currentStep.removeClass("has_error");
        const checked = $(".packagesList input[name=packagesList]").filter(":checked").length;
        if (!checked) {
            error = true;
            $(".stepServices").addClass("has_error")
        } else if (move) {
            changePackagesStep(1, $("#headerSteps li"))
        }
    };
    // stepPackages 
    if (currentStep.hasClass("stepPackages")) {
        if (order.addPlanning && !order.showAddPlanning) {
            packageVideos.stopAllVideos(true);
            packageVideos.removeEvents(true);
            changePackagesStep(1, $("#headerSteps li"), () => {
                $(".stepPackagesRow").hide().filter('.second').show();
                order.showAddPlanning = true;
                const tepmName = order.package;
                order.package = 'planning';
                order.services_list = packagesData[order.package].services_list;
                createPackagesCardList();
                createPackageDetails();
                order.package = tepmName;
                packageVideos.updateChildren(true);
                packageVideos.clearSiblingsVideos(null, true);
                packageVideos.animations.firstIn.call(packageVideos)
            })
        } else {

            if (calendar.checkSelected()) {
                if (!error) showHideCalendar(true);
                error = true;
            } else if ($("#calendar:visible").length) {
                showHideCalendar(false)
                changePackagesStep(2, $("#headerSteps li"))
            } else if (!$("#calendar:visible").length) {
                changePackagesStep(2, $("#headerSteps li"))
            }
        }
    }
    if (currentStep.hasClass("stepAddons")) {
        const goNextStep = () => { changePackagesStep(3, $("#headerSteps li")) };
        const packagesListItem = $("#addonsAncors .packagesListItem")
        if (packagesListItem.length) {
            if (packagesListItem.filter(".active").index() == packagesListItem.length - 1) {
                if (validateAddonDateEl()) error = true;
                if (move && !error) goNextStep();
            } else {
                error = true;
                packagesListItem.filter(".active").next().click();
            };
        } else {
            if (move) goNextStep();
        }
    }
    // stepPayment
    if (currentStep.hasClass("stepPayment")) {
        const formError = validateStepPaymentForm();
        if (formError) error = true;
        if (!error) createOrder();
    };
    // } else {
    //     // stepServices
    //     $(".stepServices").removeClass("has_error");
    //     const checked = $(".packagesList input[name=packagesList]").filter(":checked").length;
    //     if (!checked) {
    //         error = true;
    //         $(".stepServices").addClass("has_error")
    //         scrollTo('body, html', $(".packagesList").offset().top - window.innerHeight / 2)
    //     }
    //     // stepPayment
    //     const formError = validateStepPaymentForm();
    //     if (formError) {
    //         if (!error) scrollTo('body, html', $(".stepPayment").offset().top - $("header").innerHeight() + 15)
    //         error = true;
    //     }
    //     if (calendar.checkSelected()) {
    //         if (!error) {
    //             error = true;
    //             showHideCalendar(true);
    //         }
    //     } else if ($("#calendar:visible").length) {
    //         showHideCalendar(false)
    //     };

    //     if (!error && validateAddonDateEl()) {
    //         error = true;
    //         scrollTo('body, html', $(".addonsItem.other.has_date").offset().top - $("header").innerHeight() + 15)
    //     }

    //     if (!error) {
    //         if (signContractSuccess) {
    //             createOrder();
    //         } else {
    //             $('#signContract').click()
    //         }
    //     }
    // };
    return error;
}

const validateAddonDateEl = () => {
    const addonDateEl = $(".addonsItem.other.has_date");
    if (addonDateEl.length) {
        const checked = addonDateEl.find("input[name=checkbox]").prop('checked');
        const dateVal = addonDateEl.find("input.addonDate").val();
        if (checked && dateVal == '') {
            $("#addonsAncors .packagesListItem[data-name=Other]").click();
            setTimeout(() => { addonDate[0].open() }, 500);
            return true;
        };
    }
    return false;
};

const validateStepPaymentForm = () => {
    submitError = false;
    const form = $("#stepPaymentForm");
    form.find(".has_error error").removeClass("has_error error");
    form.find(".error_text").remove();

    form.find("input").each(function () {
        // 
        if ($(this).val() == "" && $(this).prop('required')) {
            errorDo.call($(this), 'Required')
        };
        // tel
        if ($(this).attr("type") == "tel" && $(this).prop('required')) {
            if ($(this).val().length < 6) errorDo.call($(this), 'Incorrect number')
        };

        // для почты
        if ($(this).attr("type") == "email" && $(this).prop('required')) {
            var email_val = $(this).val();
            var test_email = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
            if (!email_val || !test_email.test(email_val)) {
                if (email_val.length) {
                    errorDo.call($(this), 'Incorrect email address')
                } else {
                    errorDo.call($(this), 'Required')
                }
            };
        };
    });
    return submitError;
}

const packageSummaryListItem = (name, price, perMonth, main = false, planning = false) => {
    return `<li class="packageSummaryListItem ${main ? 'main' : ''} ${planning ? 'planning' : ''}" data-name="${name}">
        <h5>${name}</h5>
        <span class="price">$${price} or $${perMonth}/mo</span>
        <span class="remove"></span>
    </li>`
};

$(document).on('click', '.packageSummaryListItem .remove', function () {
    if ($(this).parent().hasClass("main")) return false;
    const name = $(this).parent().attr("data-name");
    $(`.addonsItem input[value='${name}']`).prop('checked', false);
    delete order.addons[name];
    if ($(this).parent().hasClass("planning")) {
        order.packagesCardPlanning = undefined;
        order.showAddPlanning = false;
        order.addPlanning = false;
        $('.packagesList input[value=planning]').prop('checked', false);
    };
    setPaymentData();
});

let packagesData;
const getPackagesData = () => {
    if (localhost) {
        $.ajax({
            url: './packagesData.json',
            dataType: 'json',
            async: false,
            success: (data) => {
                packagesData = data;
            }
        });
    } else {
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: { 'action': 'packetsender' },
            type: 'POST',
            dataType: "json",
            async: false,
            success: function (data) {
                packagesData = data;
            }
        });
    }
    if ($("#servicesVideos").length && window.innerWidth > 991) servicesVideos.init();
}


let packagesCardHover = false;
let packagesCardHoverTimer;
$(document).on('mouseenter', '.stepPackagesRow .leftContent, #packagesSidebar', function (e) {
    clearTimeout(packagesCardHoverTimer)
    if (window.innerWidth <= 991 || mobile) return false;
    const time = 100;
    const input = $('.stepPackagesRow:visible').find('input:checked');
    const index = parseInt(input.attr('data-index'));
    // console.log("mouseenter", index == order.packagesCardHover)
    if (index == order.packagesCardHover) return false;
    order.packagesCardHover = index;
    if (packagesCardHover) {
        packagesCardHover = false;
        const planning = (() => {
            if (order.showAddPlanning) return true;
            return false;
        })();
        order[!planning ? 'packagesCard' : 'packagesCardPlanning'] = index;
        $(".stepPackagesRow .text, .packageDetails").stop(true).fadeTo(time, 0)
        showCurrentCharacters(planning, index);
        packagesCardHoverTimer = setTimeout(() => {
            createPackageDetails(planning, true);
            $(".stepPackagesRow .text, .packageDetails").fadeTo(time, 1)
            runSpincrement();
        }, time);
    };

});


$(document).on('mouseenter', '.packagesCard label', function () {
    clearTimeout(packagesCardHoverTimer)
    const input = $(this).find("input");
    const index = parseInt(input.data("index"));
    const checked = input.prop("checked");
    if (window.innerWidth <= 991 || mobile) return false;
    if (checked && index == order.packagesCardHover) return false;
    const time = 100;
    // $(this).find("input").prop('checked', true).trigger('change');
    $(".stepPackagesRow .text, .packageDetails").stop(true).fadeTo(time, 0)
    // const planning = input.attr("name") === 'packagesCardPlanning';
    // const index = parseInt(input.attr('data-index'));
    // order[!planning ? 'packagesCard' : 'packagesCardPlanning'] = index;
    packagesCardHoverTimer = setTimeout(() => {
        const index = $(this).parent().index();
        input.trigger('change');
        order.packagesCardHover = index;
        const planning = input.attr("name") === 'packagesCardPlanning';
        createPackageDetails(planning, true, index)
        showCurrentCharacters(planning, index);
        $(".stepPackagesRow .text, .packageDetails").fadeTo(time, 1)
        runSpincrement();
    }, time);
    packagesCardHover = true;
});

$(document).on('mouseleave', '.packagesCard label', function (e) {
    const input = $(this).closest(".packagesCardList").find("input:checked");
    const planning = input.attr("name") === 'packagesCardPlanning';
    const index = parseInt(input.attr('data-index'));
    console.log("leave")
    order[!planning ? 'packagesCard' : 'packagesCardPlanning'] = index;
    // if (!$(e.target).closest(".packagesCard").length) {
    //     createPackageDetails(planning, true);
    // }
});

$(document).on('change', '.packagesCard input', function () {
    const planning = $(this).attr("name") === 'packagesCardPlanning';
    // if (planning) {
    //     order.packagesCardPlanning = parseInt($(this).attr('data-index'));
    // } else {
    //     order.packagesCard = parseInt($(this).attr('data-index'));
    // }
    createPackageDetails(planning, true);
});

$(document).on('change', '.packagesCard input', function () {
    const packagesCard = $("input[name=packagesCard]:checked");
    const packagesCardPlanning = $("input[name=packagesCardPlanning]:checked");
    if (parseInt(packagesCard.attr("data-index")) != order.packagesCard) {
        if (isNaN(order.packagesCard)) order.packagesCard = parseInt($("input[name=packagesCard]:checked").attr("data-index"));
        order.addons = {};
        order.packagesCard = parseInt(packagesCard.attr("data-index")) || 0;
        if (packagesCardPlanning) {
            order.packagesCardPlanning = parseInt(packagesCardPlanning.attr("data-index"));
        };
        showCurrentCharacters($(this).attr("name") == 'packagesCardPlanning', $(this).data('index'));
        createAddonsCategory();
        setPaymentData();
    };
});
let headerMenuBtnTimer;
$(document).ready(function () {
    if ($(".wrapper.packages").length) {
        if ($("#packages").hasClass("thanks")) {
            $("#headerSteps li").addClass('active current');
            $("#headerSteps").addClass('noMargin');
        }
        $("body").addClass("packagesPage")
        $("header").addClass("p-packages hasPackages");
        $("header .header-line").append($("#headerSteps"));
        $("header .header-line").append($("#headerMenuBtnWrap"));
        $("#headerMenuBtn").click(function () {
            if (!headerMenuBtnAccess) return false;
            clearTimeout(headerMenuBtnTimer)
            headerMenuBtnAccess = false;
            $('header').toggleClass("showMenu");
            const open = $('header').hasClass("showMenu");
            if (open) {
                headerMenuBtnTimer = setTimeout(() => { $(this).click() }, 1000 * 60);
            };
            $(open ? "#headerSteps" : ".header-menu").fadeOut(function () {
                $(open ? ".header-menu" : "#headerSteps").fadeIn(function () {
                    headerMenuBtnAccess = true;
                });
            });
        });
        if (!$(".packagesBlock.thanks").length) {
            clearStepPackagesRow = $(".stepPackagesRow.second").html();
            getPackagesData();

            $("#selectDate").click(function () {
                showHideCalendar(!$("#calendar:visible").length)
            });
            $("#choose, .packagesSidebar .bg").click(function () {
                showHideCalendar(false)
            });

            if (window.innerWidth <= 991) {
                $("#packagesSidebar").addClass('disabled')
                // $(".packagesList label").eq(1).find("input").prop('checked', 'checked').trigger('checked')
                // createStepPackages();
                showHidePackagesSidebar(true);
                // $("#nextStep").text('payment')
                $(".packagesSidebar .perMonth span").text('0,00');
                $(".packagesSidebar .totalCash span").text('$0,00');
            };
            $(".stepPackagesRow").hide().filter('.first').show();

            $("#stepPaymentForm input[name=phone]").on('input', function () {
                const val = $(this).val();
                $(this).val(val.replace(/\D+/g, '').slice(0, 20));
            });
        };
        $(".packagesList label").mouseleave(function () {
            $(this).removeClass("disabled");
        });
        $(".packagesList input[name=packagesList]").change(function () {
            $(".stepServices").removeClass('has_error');
            const { photo, video, planning } = getCheckedList();

            const checked = $(this).prop("checked");
            if (!checked) $(this).parent().addClass("disabled")

            const parentIndex = $(this).parent().index();

            if (servicesVideos.inited && window.innerWidth > 991) {
                servicesVideos.animations[checked ? 'teamSelected' : 'teamUnSelected'].call(servicesVideos, parentIndex);
            }

            let index = 0;

            if (photo && !video && !planning) index = 1;
            if (photo && video && !planning) index = 2;
            if (photo && !video && planning) index = 3;
            if (!photo && video && !planning) index = 4;
            if (!photo && video && planning) index = 5;
            if (!photo && !video && planning) index = 6;
            // all
            if (photo && video && planning) index = 7;

            changePackagesListImage(index);
            createStepPackages();
            showHidePackagesSidebar(index > 0 ? true : (window.innerWidth > 991 ? false : true))
            $("#packagesSidebar")[index == 0 ? 'addClass' : 'removeClass']('disabled')
        });


        $("#headerSteps li").click(function () {
            if (!changePackagesStepAccess) return false;
            if ($(this).hasClass("current") && order.showAddPlanning) {
                changePackagesStep($(this).index(), $(this).parent().find('li'));
                return true;
            };
            if (order.addPlanning && !order.showAddPlanning && $(".packagesStep.active").hasClass("stepServices")) {
                changePackagesStep(1, $("#headerSteps li"));
                return true;
            };
            if ($(this).hasClass("current")) return false;
            // $(this).add($(this).siblings()).removeClass("current");
            // $(this).addClass("current");
            // $(this).add($(this).prevAll()).addClass("active");
            // $(this).nextAll().removeClass("active");
            // 
            const currentIndex = $(this).parent().find('.current').index();
            // if (currentIndex == 0) {
            //     if (!validatePackages(false)) {
            //         changePackagesStep(1, $(this).parent().find('li'));
            //     };
            // } else {
            //     changePackagesStep($(this).index() > currentIndex ? currentIndex + 1 : $(this).index(), $(this).parent().find('li'));
            // };

            if ($(this).index() < currentIndex) {
                return changePackagesStep($(this).index(), $(this).parent().find('li'));
            }
            if (!validatePackages(false)) {
                changePackagesStep($(this).index() > currentIndex ? currentIndex + 1 : $(this).index(), $(this).parent().find('li'));
            };

        });


        $("#packages").submit(function (e) {
            e.preventDefault();
        });

        $("#nextStep").click(function () {
            if (!$(this).hasClass("disabled")) {
                $("#packagesSidebar").css("overflow", "");
                validatePackages();
                $(".notification:visible").fadeOut(250)
            } else {
                $("#packagesSidebar").css("overflow", "visible");
                $(".notification").fadeIn(250);
                $(document).on('click.notification', function (e) {
                    if (!$(e.target).closest(".buttonWrap").length) {
                        $(document).off('click.notification');
                        $(".notification").fadeOut(250, () => {
                            $("#packagesSidebar").css("overflow", "");
                        });
                    }
                })
            }
        });

        packageVideos.init();

    }

});

const setPaymentData = () => {
    const packagesCard = $(".packagesCardList input:checked");
    const index = parseInt(packagesCard.attr('data-index'));
    const current = order.services_list[index];
    // 
    $(".stepPayment .packageName").text(packagesData[order.package].name)
    $(".stepPayment .packageDescription").text(current.content.title)
    $(".stepPayment .price .totalPrice span").text(current.price.total)
    $(".packagesSidebar .totalCash b > span").text('$' + current.price.total)
    $(".currentPrice.price .totalCash span").text(current.price.total)
    $(".stepPayment .price, .currentPrice.price").find(".perMonth span").text(current.price.months);
    // 
    const stepServicesActive = $(".stepServices.active").length;
    const packagesCardPlanning = $("input[name=packagesCardPlanning]:checked");
    const total = (() => {
        let total = current.price.total;
        let months = current.price.months;
        for (const key in order.addons) {
            const { price, perMonth, other, discount, count } = order.addons[key];
            total += parseFloat(price);
            months += parseFloat(perMonth);
            if (other && count > 1) {  // other addons
                total += (count - 1) * (price * (1 - parseFloat(discount)));
                months += (count - 1) * (perMonth * (1 - parseFloat(discount)));
            };
        };
        if (order.addPlanning) {
            const index = parseInt(packagesCardPlanning.attr("data-index"));
            const { price } = packagesData.planning.services_list[index];
            total += parseFloat(price.total);
            months += parseFloat(price.months);
        };
        return { months, total }
    })();
    order.price = total;
    $(".stepPayment .packageSummaryListItem.total .price span").text(total.total);
    $(".stepPayment .payMethods label").each(function (index) {
        const cost = !index ? total.total : total.months.toFixed(2);
        $(this).find("input").attr('data-price', cost)
        $(this).find("b span").text(cost)
    });
    $(".packagesSidebar .price")[stepServicesActive && window.innerWidth > 991 ? 'removeClass' : 'addClass']("showTotal");
    const { photo, video, planning } = order.packagesList;
    if (!photo && !video && !planning) {
        $(".packagesSidebar .price").removeClass("showTotal");
    };
    $(".packagesSidebar .price .perMonth span").text(total.months.toFixed(2));
    $(".packagesSidebar .price .totalCash span span").text((parseInt(parseFloat(total.total) * 100) / 100));
    if (order.services_list) {
        $(".packagesSidebar .price .start .perMonth span").text(order.services_list[0].price.months);
        $(".packagesSidebar .price .start .totalCash span span").text(order.services_list[0].price.total);
    };
    $(".stepPaymentRow .teamImage img").attr("src", order.services_list[order.packagesCard].team_image);
    // addons
    createPackageSummaryListItems();
    setPackageSummaryTotalText();
}

const createPackageSummaryListItems = () => {
    const packagesCard = $(".packagesCardList input[name=packagesCard]:checked");
    const index = parseInt(packagesCard.attr('data-index'));
    const current = order.services_list[index];
    // 
    const capitalizedPackage = (() => order.package[0].toUpperCase() + order.package.slice(1))()

    let html = packageSummaryListItem(capitalizedPackage + ' ' + current.name + ' pacckages', current.price.total, current.price.months, true);
    if (order.addPlanning) {
        const planning = packagesData.planning.services_list[order.packagesCardPlanning];
        const capitalizedPlanning = (() => packagesData.planning.name[0].toUpperCase() + packagesData.planning.name.slice(1))()
        const { name, price } = planning;
        html += packageSummaryListItem(capitalizedPlanning + ' ' + name + ' ' + packagesData.planning.name, price.total, price.months, false, true);
    };
    const sorderAddons = Object.keys(order.addons).sort().reduce((r, k) => (r[k] = order.addons[k], r), {});
    for (const key in sorderAddons) {
        let { name, price, perMonth, other, count, discount } = sorderAddons[key];
        if (other) {
            name = key;
            if (count > 1) {  // other addons
                price = parseFloat(price) + (count - 1) * (parseFloat(price) * (1 - parseFloat(discount)));
                perMonth = parseFloat(perMonth) + (count - 1) * (parseFloat(perMonth) * (1 - parseFloat(discount)));
            };
        }
        html += packageSummaryListItem(name, price, perMonth);
    };
    $("#packageSummaryList li").remove();
    $("#packageSummaryList").append(html);

    // $("#packageSummaryList")[Object.keys(order.addons).length ? 'removeClass' : 'addClass']("isEmpty");
};

$(document).on('change', '.addonsItem input[type=checkbox]', function () {
    const name = $(this).val();
    const price = $(this).attr("data-price");
    const perMonth = $(this).attr("data-price-months");
    const checked = $(this).prop('checked');
    const parent = $(this).closest('.addonsItem');
    const other = parent.hasClass('other');
    const categoryName = $(this).closest('.addonsCategory').find(".addonsItem.title span").text();
    // 
    if (checked) {
        if (other) {
            order.addons[categoryName] = { price, perMonth, name };
            parent.addClass("active black");
            parent.find("input[name=hours]").val(1).trigger('change')
        } else {
            order.addons[name] = { price, perMonth, name };
        }
    } else {
        delete order.addons[other ? categoryName : name];
        if (other) {
            parent.removeClass("active black");
            parent.find("input[name=hours]").val(0).trigger('change')
        }
    };
    setPaymentData();
    createSelectedAddons();
});

const createOrderData = () => {
    const accountDetails = (() => {
        const form = $("#stepPaymentForm");
        const first_name = form.find('input[name=first_name]').val();
        const last_name = form.find('input[name=last_name]').val();
        const email = form.find('input[name=email]').val();
        const phone = form.find('input[name=phone]').val();
        const referral_code = form.find('input[name=referral_code]').val();
        return { first_name, last_name, email, phone, referral_code }
    })();
    return {
        docuEvent: docuEvent,
        addons: order.addons,
        package: order.package,
        packages_list: order.packagesList,
        service: order.services_list[order.packagesCard],
        add_planning: order.addPlanning,
        add_planning_service: packagesData.planning.services_list[order.packagesCardPlanning],
        account_details: accountDetails,
        price: order.price,
        pay_method: $(".payMethods input[name='radio']:checked").val(),
        calendar: calendar.getData(),
    };
}

const createOrder = () => {
    $("#nextStep").addClass("loading");
    $.ajax({
        dataType: "json",
        url: '/wp-admin/admin-ajax.php',
        data: { 'action': 'finaldatareceiver', data: createOrderData() },
        type: 'POST',
        async: false,
        success: (e) => {
            $("#nextStep").removeClass("loading");
            finaldatareceiver(e);
        }
    });
    // $.ajax({
    //     type: "POST",
    //     contentType: 'application/json',
    //     dataType: 'json',
    //     url: '/order',
    //     cache: false,
    //     data: data,
    //     success: () => {
    //         alert("done")
    //     },
    // })
}

let hold_scroll_pageStatus = false;
function hold_scroll_page(fix = false, fixBody = false, top = window.pageYOffset) {
    hold_scroll_pageStatus = fix;
    if (fix) {
        $("body").css({ 'touch-action': 'none' }).addClass("fixed")
        window.addEventListener('wheel', preventDefault, { passive: false });
        window.addEventListener('DOMMouseScroll', preventDefault, { passive: false });
        document.addEventListener('touchmove', preventDefault, { passive: false });
        if (fixBody) {
            animateFromTo('.bodyWrap', isIOS ? 1 : 0.001, { y: -window.pageYOffset }, { y: -top, ease: ease })
            $("body, html").css({
                "overflow": 'hidden',
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            })
                .height(vhCheck().vh)
            $("html").css({ position: 'relative' })


            // TweenMax.set('.bodyWrap', { y: -top })
            // scrollTo('body', top, 1)
        }
    } else {
        $("body").css({ 'touch-action': '' }).removeClass("fixed")
        window.removeEventListener('wheel', preventDefault, { passive: false });
        window.removeEventListener('DOMMouseScroll', preventDefault, { passive: false });
        document.removeEventListener('touchmove', preventDefault, { passive: false });
        if (fixBody) {
            // $("body, html").removeAttr("style")
            $("body, html").css({
                "overflow": '',
                position: ""
            }).height('')
            // TweenMax.set('.bodyWrap', { y: 0 })
            $(".bodyWrap").removeAttr("style")
            scrollTo('body, html', top, 0.001)
        }
    }
}

var ts;
$(document).on('touchstart', function (e) {
    ts = e.originalEvent.touches[0].clientY;
});

function preventDefault(e) {
    e = e || window.event;
    var area;
    if ($(e.target).closest(".packagesSidebar").length) {
        area = $(e.target).closest(".packagesSidebar");
    } else {
        area = $(e.target);
    };

    if (window.innerWidth < 630) {
        if ($(e.target).closest(".daysList").length) {
            area = $(e.target).closest(".daysList");
        }
    }
    var parentPopup = $(e.target).closest(".packagesSidebar, .packagesSidebar").length;
    if (!parentPopup) {
        e.preventDefault();
        e.returnValue = false;
        return false;
    };
    var delta = e.deltaY || e.detail || e.wheelDelta;
    if (e.type == "touchmove") {
        var tob = e.changedTouches[0] // reference first touch point for this event
        var offset = parseInt(tob.clientY)
        if (ts < offset - 5) {
            delta = -100;
        } else if (ts > offset + 5) {
            delta = 100;
        };
    };
    if (delta <= 0 && area[0].scrollTop <= 0) {
        e.preventDefault();
    };
    if (delta > 0 && area[0].scrollHeight - area[0].clientHeight - area[0].scrollTop <= 1) {
        e.preventDefault();
    };
};

const createAddonsItem = ({ black, name, gift, image, price, other, has_date, discount, categoryName, disable_list = [] }) => {
    const priceHtml = (() => {
        if (other) {
            return `<span class="price">
            $${price.total} per hour
            ${gift ? `<span class="gift" data-text='${gift}'></span>` : ''}</span>`
        } else {
            return `<span class="price">${price.months}/mo* or $${price.total}
            ${gift ? `<span class="gift" data-text='${gift}'></span>` : ''}</span>`
        }
    })();

    const hoursHtml = (() => {
        if (!other) return '';
        return `<div class="hoursCount">
            <span>Hours</span>
            <div class="wrap">
                <div class='button less'><svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.8125 0.9375H0.1875C0.0703125 0.9375 0 1.03125 0 1.125V1.875C0 1.99219 0.0703125 2.0625 0.1875 2.0625H8.8125C8.90625 2.0625 9 1.99219 9 1.875V1.125C9 1.03125 8.90625 0.9375 8.8125 0.9375Z" fill="white"/></svg></div>
                <input type="hidden" name="hours" value="0">
                <span>0</span>
                <div class='button more'><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.8125 3.9375H5.0625V0.1875C5.0625 0.09375 4.96875 0 4.875 0H4.125C4.00781 0 3.9375 0.09375 3.9375 0.1875V3.9375H0.1875C0.0703125 3.9375 0 4.03125 0 4.125V4.875C0 4.99219 0.0703125 5.0625 0.1875 5.0625H3.9375V8.8125C3.9375 8.92969 4.00781 9 4.125 9H4.875C4.96875 9 5.0625 8.92969 5.0625 8.8125V5.0625H8.8125C8.90625 5.0625 9 4.99219 9 4.875V4.125C9 4.03125 8.90625 3.9375 8.8125 3.9375Z" fill="white"/></svg></div>
            </div>
        </div>`
    })();

    const selectDateHtml = (() => {
        return `<span class="label selectDate">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 8.625H5.57143C5.78571 8.625 6 8.46094 6 8.25V6C6 5.8125 5.78571 5.625 5.57143 5.625H3C2.75893 5.625 2.57143 5.8125 2.57143 6V8.25C2.57143 8.46094 2.75893 8.625 3 8.625ZM10.7143 1.5H9.42857V0.375C9.42857 0.1875 9.21429 0 9 0H8.14286C7.90179 0 7.71429 0.1875 7.71429 0.375V1.5H4.28571V0.375C4.28571 0.1875 4.07143 0 3.85714 0H3C2.75893 0 2.57143 0.1875 2.57143 0.375V1.5H1.28571C0.5625 1.5 0 2.01562 0 2.625V10.875C0 11.5078 0.5625 12 1.28571 12H10.7143C11.4107 12 12 11.5078 12 10.875V2.625C12 2.01562 11.4107 1.5 10.7143 1.5ZM10.7143 10.7344C10.7143 10.8281 10.6339 10.875 10.5536 10.875H1.44643C1.33929 10.875 1.28571 10.8281 1.28571 10.7344V3.75H10.7143V10.7344Z" fill="#2D9CDB"/></svg>
        <span class="default">Select date</span><span class="selected">Selected date</span>`;
    })();

    return `<div class="addonsItem ${black ? 'black' : ''} ${other ? 'other' : ''} ${has_date ? 'has_date' : ''}">
        <label>
            <input type="checkbox" name="checkbox" value="${name}" data-price-months="${price.months}"
                data-price="${price.total}" ${discount ? 'data-discount=' + discount : ""} name="${name}" data-category-name="${categoryName}">
            <span class="content">
                <span class="name">${name}</span>
                ${priceHtml}
                ${(!other && !has_date) ? '<span class="label"><span class="default">select</span><span class="selected">cancel</span>' : ''}
                ${other ? hoursHtml : ''}
                </span>
            </span>
            <span class="image">
                <img src="${image ? image : '/wp-content/themes/cinefilm/img/favicon/apple-touch-icon.png'}" alt="${name}">
                ${has_date ? selectDateHtml : ''}
            </span>
        </label>
        ${has_date ? `<input type="hidden" class="addonDate" ${disable_list.length ? "data-disable_list='" + JSON.stringify(disable_list) + "'" : ""}>` : ''}
    </div>`;
};

const createAddonsAncors = () => {
    const { photo, video, planning } = order.packagesList;
    let addons = packagesData[order.package].addons;
    if ((photo || video) && planning) {
        addons = [...addons, ...packagesData.planning.addons];
    };
    let html = addons.reduce((sumCategory, currentCategory) => {
        return sumCategory + `<label class="packagesListItem" data-name="${currentCategory.name}">
            <span>${currentCategory.name}</span>
        </label>`
    }, '');
    if (packagesData.other_addons) {
        // const filteredArr = packagesData.other_addons.filter(({ avaliable_packages }) => avaliable_packages.includes(order.services_list[order.packagesCard].name));
        // if (filteredArr.length) {
        html += `<label class="packagesListItem" data-name="Other"><span>Other</span></label>`;
        // };
    };
    $("#addonsAncors").html(html);
    if (planning && photo && video && packagesData.other_addons) {
        const other = $("#addonsAncors .packagesListItem[data-name='Other']");
        other.prev().before(other)
    };
};

const createAddonsCategory = () => {
    createAddonsAncors();
    addonsAncorPos = [];
    const { photo, video, planning } = order.packagesList;
    let addons = packagesData[order.package].addons;
    if ((photo || video) && planning) {
        addons = [...addons, ...packagesData.planning.addons];
    };
    // let html = '';
    let html = addons.reduce((sumCategory, currentCategory, index) => {
        const items = currentCategory.list.reduce((sum, current) => sum + createAddonsItem({ ...current, other: false }), '');
        addonsAncorPos.push({ name: currentCategory.name, left: 0 });
        return sumCategory + `<div class="addonsCategory ${!index ? 'active' : ''}"" data-name="${currentCategory.name}">
            <div class="addonsItem black title">
                <span>${currentCategory.name}</span>
            </div>
            <div class="wrap">${items}</div>
        </div>`;
    }, '');
    let oneEl = true;
    if (packagesData.other_addons && packagesData.other_addons.length) {
        // const filteredArr = packagesData.other_addons.filter(({ avaliable_packages }) => avaliable_packages.includes(order.services_list[order.packagesCard].name));
        const filteredArr = packagesData.other_addons;
        // if (filteredArr.length) {
        addonsAncorPos.push({ name: 'Other', left: 0 });

        html += `<div class="addonsCategory other wrap" data-name="Other">`;
        html += filteredArr.reduce((sumOtherCategory, currentOtherCategory) => {
            if (currentOtherCategory.list.length > 1) oneEl = false;
            const items = currentOtherCategory.list.reduce((sum, current) => sum + createAddonsItem({ ...current, other: true, categoryName: currentOtherCategory.name }), '');
            return sumOtherCategory + `<div class="addonsCategory other">
                <div class="addonsItem black title">
                    <span>${currentOtherCategory.name}</span>
                </div>
                <div class="wrap">${items}</div>
            </div>`
        }, '');
        // }
        html += '</div>';
    };
    destroyAddonDate();
    $("#scrollAddonsWrap .scrollAddons").html(html);
    if (oneEl) $(".addonsCategory.other.wrap").addClass('oneEl')

    if (planning && photo && video && packagesData.other_addons) {
        const other = $(".addonsCategory[data-name='Other']");
        other.prev().before(other);


        addonsAncorPos.push(addonsAncorPos.splice(addonsAncorPos.length - 2, 1)[0])
    }
    addonsCategory = $(".addonsCategory");
    initAddonDate();
};

const detectCurrentAddonAncor = (changeNextAncor = false) => {
    let ancorCurrentIndex = 0;
    if (window.innerWidth > 991) {
        if (scrollAddonsWrap == undefined) scrollAddonsWrap = document.getElementById('scrollAddonsWrap');
        const scrollLeft = scrollAddonsWrap.scrollLeft;
        let change = false;
        addonsAncorPos.forEach((ancor, index, arr) => {
            const nextLeft = (index + 1 < arr.length) ? arr[index + 1].left : 99999;
            if (!change && ancorCurrentIndex != index && scrollLeft + window.innerWidth / 2 > ancor.left - 100 &&
                scrollLeft + window.innerWidth / 2 < nextLeft - 100
            ) {
                change = true;
                ancorCurrentIndex = index;
            };
        });
    } else {
        ancorCurrentIndex = $(".packagesListItem.active").index();
    }
    if (ancorCurrentIndex === addonsAncorPos.length - 1) {
        $("#nextStep").text('next')
    };
    $("#addonsAncors .packagesListItem").removeClass('active')
    // if (changeNextAncor) {
    //     ancorCurrentIndex > addonsAncorPos.length - 1 ? ancorCurrentIndex = addonsAncorPos.length - 2 : '';
    //     $("#addonsAncors .packagesListItem").eq(ancorCurrentIndex).addClass("active")
    // } else {
    if (changeNextAncor) ancorCurrentIndex++
    $("#addonsAncors .packagesListItem").eq(ancorCurrentIndex).addClass("active")
    // }
};

let addonsAncorPos = {}

$(document).on('click', '.selectedAddonsItem .remove', function () {
    const ancorName = $(this).parent().data("name");
    let input = $(`.addonsItem input[value='${ancorName}']`);
    if (order.addons[ancorName] != undefined) {
        input = input.add($(`.addonsItem input[data-category-name='${ancorName}']`));
        delete order.addons[ancorName];
    }
    input.prop('checked', false);
    input.trigger('change');
});

$(document).on('click', '#addonsAncors .packagesListItem', function () {
    const ancorName = $(this).data("name");
    const scrollLeft = addonsAncorPos.find(({ name }) => name === ancorName).left - 100;
    if (scrollAddonsWrap == undefined) scrollAddonsWrap = document.getElementById('scrollAddonsWrap');
    // createStepPackages();
    $('.addonsCategory').removeClass("active").filter(`[data-name='${ancorName}']`).addClass("active")
    TweenMax.to(scrollAddonsWrap, window.innerWidth > 991 ? 1 : 0.001, {
        scrollLeft: scrollLeft,
        ease: "Power1.easeOut",
        onComplete: () => {
            detectCurrentAddonAncor();
            $(this).addClass("active").siblings().removeClass("active")
            scrollAddonsCallBack();
            let nextStepText = 'next Add-ons';
            if ($(this).index() == $('.ancors .packagesListItem').length - 1) {
                nextStepText = 'payment';
            };
            $("#nextStep").text(nextStepText)
            if (window.innerWidth <= 991) {
                scrollTo('body, html', $("#addonsAncors").offset().top - 100, 0.001)
            };
        },
    });
});

const createSelectedAddons = () => {
    let html = '';
    $(".addonsItem input[name=checkbox]:checked").each(function () {
        let name = $(this).attr('value')
        const other = $(this).closest('.addonsItem').hasClass('other');
        if (other) {
            name = $(this).closest('.addonsCategory').find(".addonsItem.title span").text();
        }
        html += `<div class="selectedAddonsItem" data-name="${name}">
        <span class="remove"></span>
        <span class="name">${name}</span>
    </div>`;
    });
    $("#selectedAddons").html(html);
};

$(document).on('click', '.addonsItem .hoursCount', function (e) {
    e.preventDefault();
    e.stopPropagation();
});
$(document).on('click', '.addonsItem .hoursCount .button', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const more = $(this).hasClass("more");
    const input = $(this).closest(".hoursCount").find("input");
    const val = parseInt(input.val());
    let newVal = val + (more ? 1 : -1);
    input.val(newVal < 0 ? 0 : newVal);
    input.trigger('change')
});

$(document).on('change', '.addonsItem .hoursCount input', function () {
    const parent = $(this).closest('.addonsItem');
    const checkbox = parent.find('input[name=checkbox]');
    const categoryName = checkbox.data('category-name');
    const val = parseInt($(this).val());
    $(this).closest(".addonsItem")[val > 0 ? 'addClass' : 'removeClass']('active black')
    $(this).next().text(val);
    parent[val > 0 ? 'addClass' : 'removeClass']("active black");
    checkbox.prop('checked', val > 0 ? true : false)
    if (val) {
        const name = checkbox.val();
        const price = checkbox.attr("data-price");
        const perMonth = checkbox.attr("data-price-months");
        const discount = checkbox.attr("data-discount");

        order.addons[categoryName] = { price, perMonth, name, other: true, discount, count: val };
    } else {
        delete order.addons[categoryName];
    }
    setPaymentData();
    createSelectedAddons();

});

$(document).on('click', '.addonsItem.has_date .selectDate', function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (addonDate.length) addonDate[0].open()
});

let addonDate = [];
$(document).on('change', '.addonsItem.has_date .addonDate', function () {
    const val = $(this).val();
    const parent = $(this).closest(".addonsItem");
    const count = parseInt(parent.find("input[name=checkbox]").val());
    parent[val ? 'addClass' : 'removeClass']("active black selected");
    if (!count && val) parent.find(".more").trigger('click');
    if (val) parent.find(".selectDate .selected").text(val.split('-').reverse().join('.'))
});


const initAddonDate = () => {
    $(".addonsItem.has_date .addonDate").each(function () {
        const disableArr = $(this).attr("data-disable_list") ? JSON.parse($(this).attr("data-disable_list")) : [];
        const flat = flatpickr($(this)[0], {
            disableMobile: "true",
            monthSelectorType: "static",
            dateFormat: "Y-m-d",
            disable: disableArr,
            //disable: ["2020-08-20", "2020-08-21", "2020-08-23" ],
            onReady: function () {
                //   $('span.arrowUp').html('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path></svg>');
                //   $('span.arrowDown').html('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z"></path></svg>');
            }
        });
        addonDate.push(flat);
    })

};

const destroyAddonDate = () => {
    let flat = $(".addonsItem.has_date .addonDate");
    if (flat.length && flat.flatpickr() && flat.flatpickr().length) {
        try {
            flat.destroy();
        } catch (error) {

        }
    }
    addonDate = [];
};

const setPackageSummaryTotalText = () => {
    if (!calendar.current.daySelected.length) return false;
    const text = (() => {
        const { months, total } = order.price
        const radio = $(".payMethods input[name='radio']:checked").val();
        const { year, monthNumber, dayNumber } = calendar.currentDate;
        const today = new Date(year + '/' + (monthNumber + 1) + '/' + (dayNumber + 1));
        const lastDate = calendar.current.daySelected[0].split('-').reverse().join('.');
        if (radio === 'allPrice') {
            let todayPlus2Weeks = new Date(today.getTime() + (1000 * 60 * 60 * 24 * 14))
            let todayPlus2WeeksText = (todayPlus2Weeks.getUTCDate() + 1) + '.' + (todayPlus2Weeks.getUTCMonth() + 1) + '.' + (todayPlus2Weeks.getUTCFullYear());
            if (new Date(todayPlus2Weeks) > new Date(lastDate.split('.').reverse().join('/'))) {
                todayPlus2WeeksText = lastDate;
            };
            return `<b>$500</b> is due today, <b>$${parseInt((parseFloat(total) - 500) * 0.3)}</b> (30% of your remaining balance) is dye by <b>${todayPlus2WeeksText}</b> and <b>$${parseInt(parseFloat(total) - (parseFloat(total) - 500) * 0.3)}</b> (remaining balance) is due by 1 week prior to <b>${lastDate}</b>`
        } else {
            const monthsBetween = calendar.getDiffMonth(today, new Date(lastDate.split('.').reverse().join('/')))
            const monthsPrice = (parseFloat(total) - 500) / monthsBetween;

            return `<b>$500</b> is due today, ${monthsBetween} of month before wedding, <b>$${monthsPrice == Infinity ? 0 : monthsPrice}</b> monthly payments`;
        }
    })()
    $(".packageSummary .totalText").html(text);
};

$(document).on('input', '.payMethods input', setPackageSummaryTotalText);


$(document).on('click', '.addonsItem .gift', function (e) {
    e.preventDefault();
    return false;
})


if (window.devicePixelRatio == 1) {
    $("body, html").addClass("devicePixelRatio_1");
}

$(document).on('click', '#prevStep', function () {
    const currentStep = $(".packagesStep.active");
    let index = currentStep.index() - 1;
    if (index < 0) return false;
    changePackagesStep(index, $("#headerSteps li"));
});

$(document).on('click', '#signContract', function () {
    let error = false;
    // stepServices
    $(".stepServices").removeClass("has_error");
    const checked = $(".packagesList input[name=packagesList]").filter(":checked").length;
    if (!checked) {
        error = true;
        $(".stepServices").addClass("has_error")
        scrollTo('body, html', $(".packagesList").offset().top - window.innerHeight / 2)
    };
    // stepPayment
    if (validateStepPaymentForm()) {
        if (!error) scrollTo('body, html', $(".stepPayment").offset().top - $("header").innerHeight() + 15)
        error = true;
    };
    if (calendar.checkSelected()) {
        if (!error) {
            error = true;
            showHideCalendar(true);
        }
    } else if ($("#calendar:visible").length) {
        showHideCalendar(false)
    };
    if (!error && validateAddonDateEl()) {
        error = true;
        scrollTo('body, html', $(".addonsItem.other.has_date").offset().top - $("header").innerHeight() + 15)
    };
    // 

    if (error) return false;
    signContractSuccess = true;
    $("#nextStep").removeClass("disabled");
    $(this).addClass("active");
    $('body').addClass("loading");
    $('body').after(loadingIcon)
    openDocuWindow('/docu_page.php')
    $.ajax({
        dataType: "json",
        url: '/wp-admin/admin-ajax.php',
        data: { 'action': 'makesign', data: createOrderData() },
        type: 'POST',
        success: ({ href }) => {
            $('.loadingIcon').remove()
            $('body').removeClass("loading");
            // if (mobile) {
            docuWindow.location.href = href
            // } else {
            //     openDocuWindow(href)
            // }
        },
        // error: function (error) {
        //     console.log('error', error)
        // },
        // complete: function (data) {
        //     console.log('complete ', data)
        // },
        // ajaxComplete: function (data) {
        //     console.log('ajaxComplete  ', data)
        // },
    });
})
let docuEvent = '';
let docuWindow;

const openDocuWindow = (url = '/') => {
    let height = window.screen.height - 200;
    let width = window.screen.width - 200;
    width > 1600 ? width = 1600 : ''
    height > 1200 ? height = 1200 : ''
    let left = (window.screen.width - width) / 2
    let top = (window.screen.height - height) / 2
    docuWindow = window.open(url, mobile ? '_blank' : 'docuWindow', `location=no,menubar=no,toolbar=no,left=${left},top=${top},width=${width},height=${height}`)
    docuEvent = 'user closed the window'
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
// docu redirect event
if (getParameterByName('event') != null) {
    if (!mobile && opener != null) opener.postMessage({ type: 'docu', event: getParameterByName('event') })
    setTimeout(() => {
        if (mobile) {
            window.open('', '_parent', '');
            window.close();
            window.open('', '_self').close();
        } else {
            window.close()
        }
    }, 2250);
}

function docuListener({ data }) {
    if (data.type === 'docu') docuEvent = data.event;
}

if (window.addEventListener) {
    window.addEventListener("message", docuListener);
} else {
    // IE8
    window.attachEvent("onmessage", docuListener);
}

const loadingIcon = `<svg id='loadingIcon' class='loadingIcon' xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#314FB3">
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
            </path>
        </g>
    </g>
</svg>`;

// var iframe = document.getElementById('iframe');
// var player = new Vimeo.Player(iframe);
// player.on('play', function() {
//     console.log('Played the video');
//   });
// player.on('timeupdate', function(e) {
//     console.log('timeupdate', e);
//   });

//   player.getVideoTitle().then(function(title) {
//     console.log('title:', title);
//   });