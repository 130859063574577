class InfiniteScroll {
    constructor(disabled = false) {
        this.elems = $('.sect-main-3 .b-img')
        this.html = `<div class="b-img">
            <img data-src="img/img-13.jpg"
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                class="lazyCustom" alt="">
            <span>Palm Springs, California, Samantha and Ando</span>
        </div>`;
        this.images = this.elems.length;
        this.imagesLoad = 0;
        this.mouse = { x: 0, y: 0 };
        this.ease = Power3.easeOut;
        this.grid = [];
        this.infiniteScroll = $(".infiniteScroll");
        this.disabledParalaxInit = disabled;
        this.infiniteScrollTop = this.infiniteScroll.offset().top;
        this.fixedPage = false;
        this.scrollBeforeFixed = true;
        this.holdPageOnScrollAccess = true;
        this.init();

    };
    init() {
        TweenMax.set('.infiniteScroll .gp-1', {
            height: window.innerHeight - (window.innerWidth <= 991 ? 62 : 0),
            ease: this.ease
        })
        this.elems.each(function (index) {
            $(this).attr("data-index", index)

            $(this).find("span.horiz, span.vert").wrapAll("<div class='textWrap'></div>")
            $(this).find('.textWrap').append("<div class='view'>View</div>")
        })

        this.appendedCount = 0;
        this.gridCount = Math.round(Math.sqrt(this.images));
        // 
        let html = '<div class="row"></div>';
        $(".absoluteGrid").html(html);
        this.grid.push(new Array(this.gridCount))
        this.countInMainRow = this.gridCount;
        if (this.gridCount % 2 > 0) this.countInMainRow--;
        let row = $(".absoluteGrid .row").last();
        for (let index = 0; index < this.countInMainRow; index++) {
            row.append(this.elems.eq(this.appendedCount++))
        };
        const b_img = $(".absoluteGrid .row .b-img");
        b_img.eq(Math.floor(b_img.length / 2)).before($(".gp-1-info-t"));
        // 
        this.grid = (() => {
            let arr = [];
            this.gridCount = Math.round(Math.sqrt(this.images - this.countInMainRow));
            for (let index = 0; index < this.gridCount; index++) {
                arr.push(new Array(this.gridCount))
            }
            return arr;
        })();
        const shiftCount = this.images - this.countInMainRow - this.gridCount * this.gridCount;
        if (shiftCount > 0) {
            for (let index = 0; index < shiftCount; index++) {
                this.grid[index].push(null)
            };
        };

        this.createRowArr();

        this.infiniteScroll.find(".scrollDown").click((e) => {
            if (window.innerWidth > 991) {
                if ($(e.target).hasClass("top")) {
                    screenCallbackBefore(1, true, 'moveUp', () => { pagescroll.moveUp() });
                } else {
                    screenCallbackBefore(1, true, 'moveDown', () => { pagescroll.moveDown() });
                }
            } else {
                if (mainPage) {
                   
                    this.infiniteScroll.find(".scrollDown").fadeOut(250);
                    this.parallax.disable();
                    // TweenMax.to('.infiniteScroll .b-imgs', 0.5, { opacity: 0, ease: ease })
                    this.holdPageOnScrollResize();
                    TweenMax.set('.sect-main-1', { opacity: 0 })
                    TweenMax.to('.infiniteScroll .b-imgs', 0.2, {
                        opacity: 0, ease: ease, onComplete: () => {
                            this.infiniteScroll.removeClass("fixed")
                            $(".sect-main-2").after($(".infiniteScroll"))
                            this.holdPageOnScrollResize();
                            hold_scroll_page(false, true, this.infiniteScrollTop);
                            if ($(e.target).hasClass("top")) {
                                this.scrollBeforeFixed = true;
                                const topShift = this.infiniteScroll.prev().offset().top + this.infiniteScroll.prev().innerHeight() / 2;
                                scrollTo('body, html', topShift - window.innerHeight / 2, 1)
                                setTimeout(() => {
                                    TweenMax.set('.sect-main-1', { opacity: 1 })
                                }, 5);
                            } else {
                                // console.log('this.infiniteScrollTop', this.infiniteScrollTop)
                                this.scrollBeforeFixed = false;
                                scrollTo('body, html', this.infiniteScrollTop + window.innerHeight - 20, 1)
                            };
                            setTimeout(() => {
                                const topShift = (() => {
                                    if (window.innerWidth < 991) return 150
                                    return 350;
                                })()
                                TweenMax.set('.b-imgs', { y: topShift * (this.scrollBeforeFixed ? 1 : -1), opacity: 1 })
                                this.parallax.resetMouse(true, true);
                                this.parallax.disable();
                                this.holdPageOnScrollAccess = true;
                                TweenMax.set('.sect-main-1', { opacity: 1, visibility: "" })
                            }, 1000);
                            this.fixedPage = false;
                            // this.holdPageOnScrollAccess = true;
                        }
                    })

                    // 
                } else {
                    if (this.infiniteScroll.hasClass("hide")) {
                        this.infiniteScroll.removeClass("hide");
                        $("body, html").scrollTop(0).css({
                            "overflow": 'hidden',
                            position: "fixed"
                        }).height(vhCheck().windowHeight)
                        TweenMax.to('.infiniteScroll .gp-1', 1, {
                            height: window.innerHeight - (window.innerWidth <= 991 ? 62 : 0),
                            ease: this.ease
                        })
                        TweenMax.to('.infiniteScroll', 1, {
                            height: window.innerHeight,
                            ease: this.ease
                        });
                        if (mobile) {
                            this.parallax.shift.x = 0;
                            this.parallax.shift.y = 0;
                            this.parallax.tempShift = { ...this.parallax.defaultShift };
                            this.parallax.tween.to(this.parallax.absoluteGrid, 1.5, {
                                x: this.parallax.defaultShift.x,
                                y: this.parallax.defaultShift.y,
                                ease: this.ease,
                            });
                        } else {
                            myInfiniteScroll.parallax.resetMouse(true, true);
                        }
                        this.parallax.disabled = false;
                    } else {
                        $("body, html").css({
                            overflow: '',
                            position: '',
                            height: ''
                        });
                        this.parallax.disabled = true;

                        this.parallax.tween.to(this.parallax.absoluteGrid, 1.5, {
                            y: this.parallax.defaultShift.y - 400,
                            x: this.parallax.defaultShift.x,
                            ease: this.ease,
                        });
                        TweenMax.to('.infiniteScroll .gp-1, .infiniteScroll', 1, {
                            height: 200,
                            ease: this.ease,
                            onComplete: () => {
                                window.dispatchEvent(new Event("scroll"));
                            }
                        })
                        this.infiniteScroll.addClass("hide");
                    }
                }
            }
        })

        $(".infiniteScroll .b-imgs").delay(200).fadeTo(350, 1);
        if (mainPage) this.initHoldPageOnScroll();
    }

    getGridSize() {
        this.gridSize = {
            height: (() => {
                let h = Math.abs(this.absoluteGridHeight.min) + this.absoluteGridHeight.max + this.sizeImage.margin;
                // if (window.innerWidth <= 991) h -= 270;
                h -= mobile ? 200 : 100;
                return h;
            })(),
            width: (() => {
                let max = 0;
                $(".absoluteGrid .row").each(function () {
                    const width = $(this).innerWidth();
                    if (width > max) max = width;
                });
                max -= mobile ? 200 : 380;
                return max;
                // return max - (window.innerWidth <= 991 ? 110 : 0);
            })()
        }
        this.elems.css("visibility", "visible")
        if (window.innerWidth <= 991) {
            setTimeout(() => {
                this.elems.addClass("animated show clear done")
            }, 2000);
        }
        return this.gridSize;
    }

    setPosRow() {
        const absoluteGrid = $(".absoluteGrid");
        let topIndex = 1, bottomindex = 1;
        let min = 999, max = 0;
        this.sizeImage = {
            height: 300,
            margin: parseInt(absoluteGrid.find(".row").css("margin-bottom")),
        };
        const thise = this;
        absoluteGrid.find(".row").slice(1).each(function (index) {
            const top = (() => {
                if (index % 2 == 0) {
                    return (-thise.sizeImage.height - thise.sizeImage.margin) * topIndex++;
                } else {
                    return (thise.sizeImage.height + thise.sizeImage.margin) * bottomindex++;
                }
            })();
            if (index % 2 == 0) {
                if (top < min) min = top;
            } else {
                if (top > max) max = top;
            };
            thise.absoluteGridHeight = { min, max };
            $(this).css({
                position: 'absolute',
                top: top
            }).addClass("slave")
        })
    }

    createRowArr() {
        const absoluteGrid = $(".absoluteGrid");
        let topIndex = 1, bottomindex = 1;
        let min = 999, max = 0;
        this.sizeImage = {
            height: 300,
            margin: parseInt(absoluteGrid.find(".row").css("margin-bottom")),
        };
        this.grid.forEach((row, index, arr) => {
            absoluteGrid.append('<div class="row"></div>');
            const rowElem = absoluteGrid.find(".row").last();
            for (let indexInRow = 0; indexInRow < row.length; indexInRow++) {
                if (this.appendedCount + 1 <= this.elems.length) {
                    rowElem.append(this.elems.eq(this.appendedCount++));
                };
            };
            const prev = absoluteGrid.find(".row").eq(-2).find(".b-img").length;
            const last = rowElem.find(".b-img").length;
            if (index == arr.length - 1) { // last
                if (last + 1 < prev) {
                    const slice = absoluteGrid.find(".row").eq(-2).find(".b-img").add(rowElem.find(".b-img")).slice(Math.floor((prev + last) / 2));
                    rowElem.append(slice)
                };
            };
            const top = (() => {
                if (index % 2 == 0) {
                    return (-this.sizeImage.height - this.sizeImage.margin) * topIndex++;
                } else {
                    return (this.sizeImage.height + this.sizeImage.margin) * bottomindex++;
                }
            })();
            if (index % 2 == 0) {
                if (top < min) min = top;
            } else {
                if (top > max) max = top;
            };
            this.absoluteGridHeight = { min, max };
            if (index == arr.length - 1 && (prev == 0 && last == 0)) {

                this.absoluteGridHeight.min += this.sizeImage.height + this.sizeImage.margin / 2;
                this.absoluteGridHeight.max -= this.sizeImage.height + this.sizeImage.margin / 2;

            }
            rowElem.css({
                position: 'absolute',
                top: top
            }).addClass("slave")
        });
        const thise = this;
        $('.lazyCustom.infiniteImage:not(.load)').each(function () {
            $(this).on('load', function () {
                thise.loadImagesCallBack();
            });
            if ($(this).attr('data-src') != '') {
                $(this).attr('src', $(this).attr('data-src')).addClass("load");
            }
        });
        setTimeout(() => { this.getGridSize() }, 250);
    }
    loadImagesCallBack() {
        if (++this.imagesLoad >= this.images) {
            this.getGridSize();
            this.parallax = new InfiniteParallax(this, mainPage);
            $(".infiniteScroll .loading").fadeOut(250);
            setTimeout(startPagescroll, 250);
            this.elems.each(function () {
                if ($(this).innerWidth() < $(this).innerHeight()) {
                    $(this).addClass("hor");
                };
            })
        }
    }
    holdPageOnScrollResize(e) {
        if (hold_scroll_pageStatus) {
            this.infiniteScrollTop = myInfiniteScroll.infiniteScroll.position().top;
        } else {
            this.infiniteScrollTop = this.infiniteScroll.offset().top;
        }
    }
    holdPageOnScroll(e) {
        if (!this.holdPageOnScrollAccess) return false;
        const scrollTop = window.pageYOffset;
        if ((!this.fixedPage && this.scrollBeforeFixed && scrollTop > this.infiniteScrollTop) || (!this.fixedPage && !this.scrollBeforeFixed && scrollTop < this.infiniteScrollTop)) {
            this.holdPageOnScrollAccess = false;
            this.fixedPage = true;
            // hold_scroll_page(true, true, this.infiniteScrollTop);
            hold_scroll_page(true, true, 0);
            TweenMax.set('.sect-main-1', { opacity: 0, visibility: "hidden" })
            TweenMax.set('body, html, .bodyWrap', { scrollTop: 0 })
            // if (isIpad) {
                $("body").prepend($(".infiniteScroll"))
            // }
            setTimeout(() => {
                myInfiniteScroll.parallax.touchstart = myInfiniteScroll.parallax.getSwipeOffset(myInfiniteScroll.parallax.touchMoveEvent)
                myInfiniteScroll.parallax.enable();
            }, 1500);
            TweenMax.to('.infiniteScroll .b-imgs', 1, { y: 0, ease: Back.easeOut.config(1.7) })
            this.infiniteScroll.find(".scrollDown").fadeIn(1000);
            this.infiniteScroll.addClass("fixed")
        };
    }
    initHoldPageOnScroll() {
        $("body").wrapInner("<div class='bodyWrap'></div>");
        $(".bodyWrap").after($("#rotatePhone"));
        window.addEventListener('scroll', this.holdPageOnScroll.bind(this), false)
        window.addEventListener('resize', this.holdPageOnScrollResize.bind(this), false)
        const topShift = (() => {
            if (window.innerWidth < 991) return 150
            return 350;
        })()
        TweenMax.set('.b-imgs', { y: topShift })
    }
}