class PackagesCalendar {
    constructor(id) {
        this.errorTimer;
        this.mouseDown = false;
        this.mouseDownDate = null;
        this.mouseDownRange = false;
        this.timer = undefined;
        this.countYears = 5;
        this.monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.daysPrefix = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        // 
        this.parent = $('#' + id);
        this.notice = this.parent.find(".notice .yellow");
        this.dateNow = new Date();
        this.currentDate = this.getDate();
        this.current = {
            year: this.currentDate.year,
            month: this.currentDate.monthNumber,
            countDays: this.getDaysInMonth(this.currentDate.monthNumber, this.currentDate.year),
            daySelected: [],
        };
        this.thise = this;
        this.createCalendar();
        // 
        $(document).on('change.daysListItem', '#' + id + ' .daysListItem input', this.daysListItemClick.bind(this));
        $(document).on('click.yearsListItem', '#' + id + ' .yearsListItem', this.yearsListItemClick.bind(this));
        $(document).on('click.monthsListItem', '#' + id + ' .monthsListItem', this.monthsListItemClick.bind(this));
        // 
        $(document).on('mouseenter.monthsListItem', '#' + id + ' .daysListItem', this.mouseenter.bind(this));
        $(document).on('mousedown.monthsListItem', '#' + id + ' .daysListItem', this.mousedown.bind(this));
        $(document).on('mouseup.monthsListItem', this.mouseup.bind(this));
    };

    initErrorTimer() {
        clearTimeout(this.errorTimer)
        this.errorTimer = setTimeout(() => {
            $('#calendar .error').removeClass("error");
        }, 1000);
    }

    mouseenter(e) {
        if (this.mouseDown) {
            const thise = this;
            this.resetAllSelectedDays()
            const firstDateParent = this.parent.find(`input[value='${this.mouseDownDate}']`).closest('.daysListItem');
            const currentParent = $(e.target).closest('.daysListItem');
            let diff = Math.abs(currentParent.index() - firstDateParent.index());
            if (diff > 3) diff = 3;
            const sliceIndexes = (() => {
                if (currentParent.index() > firstDateParent.index()) {
                    return [firstDateParent.index(), firstDateParent.index() + diff + 1]
                };
                return [firstDateParent.index() - diff, firstDateParent.index() + 1]
            })();
            const slice = this.parent.find(".daysListItem").slice(sliceIndexes[0], sliceIndexes[1]);
            slice.each(function () {
                const val = $(this).find("input").val();
                $(this).find("input").prop('checked', true)
                $(this).addClass("active");
                thise.current.daySelected.push(val);
            })
            this.mouseDownRange = true;
            this.selectDate();
        };
    }

    mousedown(e) {
        this.mouseDown = true;
        this.mouseDownDate = e.target.closest('.daysListItem').querySelector('input').value;
    };

    mouseup() {
        if (this.mouseDown) {
            this.mouseDown = false;
            this.mouseDownRange = false;
        };
    };

    resetAllSelectedDays() {
        this.current.daySelected = [];
        this.parent.find(".daysListItem").removeClass("active").find("input").each(function () {
            $(this).prop('checked', '');
        });
        this.selectDate();
    };

    getDiffDays() {
        const diffTime = Math.abs(new Date(this.current.daySelected[this.current.daySelected.length - 1].replace(/-/g, '/')) - new Date(this.current.daySelected[0].replace(/-/g, '/')));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    getDiffMonth(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    daysListItemClick(e, dateStart) {

        const currentDate = new Date($(e.target).data('day'));
        if (currentDate < this.dateNow) {
            $(e.target).closest('.daysListItem').addClass("error");
            this.initErrorTimer();
            return false;
        }

        if (dateStart) {
            const dateStartEl = this.parent.find(`input[value='${dateStart}']`);
            dateStartEl.prop("checked", true);
        }
        const el = dateStart ? this.parent.find(`input[value='${dateStart}']`) : $(e.currentTarget);

        const checked = el.prop("checked");
        const val = el.val();
        el.closest(".daysListItem")[checked ? 'addClass' : 'removeClass']('active');
        let indexInArr = undefined;
        if (checked) {
            if (!this.current.daySelected.includes(val)) {
                this.current.daySelected.push(val)
            }
        } else {
            indexInArr = this.current.daySelected.indexOf(val);
            if (indexInArr >= 0) {
                this.current.daySelected.splice(indexInArr, 1);
            }
        }
        this.current.daySelected = this.current.daySelected.sort((a, b) => new Date(a.replace(/-/g, '/')).getTime() - new Date(b.replace(/-/g, '/')).getTime());
        const getDiffDays = this.getDiffDays();
        if (getDiffDays > 3) {
            this.resetAllSelectedDays();
            if (checked) {
                if (!this.current.daySelected.includes(val)) {
                    this.current.daySelected.push(val);
                };
                el.closest(".daysListItem").addClass('active');
            }
        } else {
            const firstDay = this.current.daySelected[0].replace(/-/g, '/');
            const lastDay = this.current.daySelected[this.current.daySelected.length - 1].replace(/-/g, '/');
            if (checked) {
                if (this.current.daySelected.length > 1) {
                    for (let index = 0; index < getDiffDays - 1; index++) {
                        const newDate = new Date(firstDay);
                        const newDateText = this.current.year + '-' + (this.current.month + 1) + '-' + (newDate.getDate() + index + 1);
                        if (!this.current.daySelected.includes(newDateText)) {
                            this.current.daySelected.push(newDateText)
                        }
                        const input = this.parent.find(`.daysListItem input[value=${newDateText}]`);
                        input.prop('checked', 'checked');
                        input.closest('.daysListItem').addClass("active");
                    }
                    this.current.daySelected = this.current.daySelected.sort((a, b) => new Date(a.replace(/-/g, '/')).getTime() - new Date(b.replace(/-/g, '/')).getTime());
                }
            } else {
                if (indexInArr > 0 && indexInArr < this.current.daySelected.length) {
                    const daySelectedClone = [...this.current.daySelected];
                    this.resetAllSelectedDays();
                    let date;
                    if (indexInArr > Math.floor(daySelectedClone.length / 2)) {
                        date = daySelectedClone[daySelectedClone.length - 1];
                    } else {
                        date = daySelectedClone[0];
                    };
                    if (!this.current.daySelected.includes(date)) {
                        this.current.daySelected.push(date)
                    }
                    const input = this.parent.find(`.daysListItem input[value=${date}]`);
                    input.prop('checked', 'checked');
                    input.closest('.daysListItem').addClass("active");
                }
            }
        };
        this.selectDate();
    };

    selectDate() {
        const span = this.parent.next().find("#selectDate span");
        const firstDay = this.current.daySelected[0];
        const lastDay = this.current.daySelected[this.current.daySelected.length - 1];
        let date;
        if (this.current.daySelected.length > 1) {
            const firstDayText = firstDay.split('-')
            date = firstDayText[firstDayText.length - 1] + ' - ' + lastDay.split('-').reverse().join('.')
        } else if (this.current.daySelected.length == 1) {
            date = lastDay.split('-').reverse().join('.')
        } else {
            date = span.attr('data-text');
        }
        span.html(date);
    };

    monthsListItemClick(e) {
        const el = $(e.currentTarget);
        if (el.data('index') < this.currentDate.monthNumber && this.current.year <= this.currentDate.year) {
            $(e.target).closest('.monthsListItem').addClass("error");
            this.initErrorTimer();
            return false;
        };
        const index = parseInt(el.attr('data-index'));
        if (index != this.current.month) {
            this.current.month = index;
            this.createCalendar(index, this.currentDate.year);
        }
    };

    yearsListItemClick(e) {
        const el = $(e.currentTarget);
        const val = parseInt(el.attr("data-val"));
        if (val != this.current.year) {
            this.current.year = val;
            let monthNumber = this.current.month;
            if (this.current.year <= this.currentDate.year && monthNumber < this.currentDate.monthNumber) {
                monthNumber = this.currentDate.monthNumber;
            };
            // debugger
            this.createCalendar(monthNumber, val);
        };
    };

    createCalendar(month = this.currentDate.monthNumber, year = this.currentDate.year) {
        this.current.month = month;
        this.current.countDays = this.getDaysInMonth(month + 1, year);
        this.resetAllSelectedDays();
        this.initYears();
        this.initMonths();
        this.initDays();
    };

    getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    };

    getDate() {
        const date = new Date();
        return {
            year: date.getUTCFullYear(),
            dayNumber: date.getUTCDate(),
            monthNumber: date.getUTCMonth()
        }
    };

    initYears() {
        let html = '';
        for (let index = 0; index < this.countYears; index++) {
            const year = this.currentDate.year + index;
            html += `<li class="yearsListItem${year == this.current.year ? ' active' : ''}" data-index="${index}" data-val="${year}">${year}</li>`;
        };
        this.parent.find(".yearsList").html(html)
    };

    initMonths() {
        let html = '';
        for (let index = 0; index < 12; index++) {
            html += `<li class="monthsListItem${index == this.current.month ? ' active' : ''}" data-index="${index}" data-val="${this.monthsList[index]}">${this.monthsList[index]}</li>`;
        };
        this.parent.find(".monthsList").html(html)
    };

    initDays() {
        let html = '';
        let viewedTotal = false;
        const dayInit = new Date(this.current.year, this.current.month, 0 + 1)
        for (let index = 0; index < dayInit.getDay() - 1; index++) {
            html += `<li class="daysListItem stub"></li>`;
        }
        for (let index = 0; index < this.current.countDays; index++) {
            const day = new Date(this.current.year, this.current.month, index + 1)
            const value = this.current.year + '-' + (this.current.month + 1) + '-' + day.getDate();
            const viewed = viewedDates.includes(value);
            if (viewed) viewedTotal = true;
            html += `<li class="daysListItem${viewed ? ' viewed' : ''}" data-index="${index}">
                <label>
                    <input type="checkbox" name="day" data-day="${day}" value="${value}">
                        <span class='number'>${day.getDate()}</span>
                        <span class='name'>${this.daysPrefix[day.getDay()]}</span>
                </label>
            </li>`;
        }
        this.parent.find(".daysList").html(html);
        this.notice[viewedTotal ? 'show' : 'hide']();
    };

    destroy() {
        $(document).off('change.daysListItem');
        $(document).off('click.yearsListItem');
    };

    checkSelected() {
        let error = false;
        if (!this.current.daySelected.length) {
            error = true;
            clearTimeout(this.timer);
            this.parent.closest("#packagesSidebar").find("#selectDate").addClass("error")
            this.timer = setTimeout(() => {
                this.parent.closest("#packagesSidebar").find("#selectDate").removeClass("error")
            }, 3000);
        } else {
            clearTimeout(this.timer);
            this.parent.closest("#packagesSidebar").find("#selectDate").removeClass("error")
        }
        return error;
    }

    getData() {
        const { daySelected, month, year } = this.current;
        return { daySelected, month, year }
    }

    click(e) {
        if (!($(e.target).closest(".yearsListItem").length || $(e.target).closest(".packagesSidebar").length || $(e.target).closest(".headerSteps").length || $(e.target).hasClass("monthsListItem"))) {
            showHideCalendar(false)
        }
    }
}
let calendar;
$(document).ready(function () {
    if ($("#calendar").length) {
        calendar = new PackagesCalendar('calendar');
    }
});
