let fixVH;
class vhDevice {
    constructor(classList = []) {
        this.height = vhCheck().windowHeight;
        this.classList = classList;
        this.init();
    }

    update() {
        this.height = vhCheck().windowHeight;
        this.set();
    }

    init() {
        if (!this.classList.length) return false;
        window.addEventListener("resize", this.update.bind(this), { passive: false })
        this.set();
    }

    destroy() {
        window.removeEventListener("resize", this.update.bind(this), { passive: false })
    }

    set() {
        this.classList.forEach(element => {
            const nodes = document.querySelectorAll(element);
            nodes.forEach(element => {
                element.style.height = window.innerWidth > 991 ? this.height + 'px' : '';
            });
        });
    }
};